<template lang='pug'>
  .logout
</template>

<script>
// @ is an alias to /src
export default {
  name: 'LogOut',
  mounted () {
    const filJwt = localStorage.fil_jwt
    if (!filJwt) {
      this.$store.dispatch('setUserDetails', { isLoggedIn: false })
    }
    this.$http.post('/users/logout', {}, { headers: { Authorization: `Bearer ${filJwt}` } })
    localStorage.removeItem('fil_jwt')
    this.$store.dispatch('setUserDetails', { isLoggedIn: false })
    let urlRoute = 'https://outfit.flippedinlove.app'
    switch (this.$route.query.origin) {
      case 'fil-outfit-explorer.web.app':
        urlRoute = 'https://fil-outfit-explorer.web.app'
        break
      // case 'localhost:8081':
      //   urlRoute = 'http://localhost:8081'
      //   break
      default:
        urlRoute = 'https://outfit.flippedinlove.app'
        break
    }
    this.$router.replace(`/outfit?o=${urlRoute}`)
  }
}
</script>

<style scoped lang="stylus">
  .settings
    padding 20px
    max-width 700px
    margin 0 auto
</style>
