<template>
  <div id="app">
    <div id="app-inner-container">
      <div class="main-header">
        <div class="logo">
          {{ isLogin ? 'Flipped in Love Tools' : 'FiL Partner Diary'}}
        </div>
      </div>
      <UserBar v-if="isLoggedIn" />
      <router-view/>
      <div class="footer">
        <div class="info">
          <div class="title">About</div>
          <div class="last-update">
            Partner data last updated on <span class="date">14th June 2021</span>
          </div>
          <div class="text">
            FiL Partner Diary is a website created by fans of Flipped in Love to help other players progress in the game. Flipped in Love content and materials are trademarks and copyrights of their respective publisher and its licensors. <span>Footer icons are made by <a target="_blank" href="https://www.freepik.com" title="Freepik">Freepik</a> from <a target="_blank" href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a>.</span>
          </div>
        </div>
        <div class="game-links">
          <div class="title">Links</div>
          <ul class="links">
            <li><a target="_blank" href="https://play.google.com/store/apps/details?id=com.more2.superstar.gp&hl=en&gl=US">Download FiL for Android</a></li>
            <li><a target="_blank" href="https://apps.apple.com/ph/app/flipped-in-love/id1529248569">Download FiL for iOS</a></li>
            <li><a target="_blank" href="https://www.facebook.com/Flipped-in-Love-103170538400016/">FiL Facebook Page</a></li>
          </ul>
          <div class="text">
            For any enquiries, contact <strong>&#102;&#105;&#108;&#111;&#117;&#116;&#102;&#105;&#116;&#098;&#117;&#105;&#108;&#100;&#101;&#114;&#064;&#103;&#109;&#097;&#105;&#108;&#046;&#099;&#111;&#109;</strong>
          </div>
        </div>
      </div>
    </div>
    <NavBar />
  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import UserBar from '@/components/UserBar.vue'

export default {
  name: 'App',
  components: {
    NavBar,
    UserBar
  },
  computed: {
    isLoggedIn: function () {
      return this.$store.state.isLoggedIn
    },
    isLogin: function () {
      return this.$route.name === 'Home'
    }
  },
  watch: {
    $route (to, from) {
      if (!to || !from || to.name !== from.name) return

      const filJwt = localStorage.fil_jwt
      if (!filJwt) {
        this.$store.dispatch('setUserDetails', { isLoggedIn: false })
        return
      }
      this.$http.get('/users/me/', { headers: { Authorization: `Bearer ${filJwt}` } })
        .then((response) => {
          this.$store.dispatch('setUserDetails', { isLoggedIn: true, username: response.data.user.username, alias: response.data.user.alias, server: response.data.user.server, group: response.data.user.group })
        }).catch((error) => {
          if (error.response && error.response.data.message === 'Authentification Failed') localStorage.removeItem('fil_jwt')
          this.$store.dispatch('setUserDetails', { isLoggedIn: false })
          return error
        })
    }
  },
  mounted () {
    const filJwt = localStorage.fil_jwt
    if (!filJwt) {
      this.$store.dispatch('setUserDetails', { isLoggedIn: false })
      return
    }

    this.$http.get('/users/me/', { headers: { Authorization: `Bearer ${filJwt}` } })
      .then((response) => {
        this.$store.dispatch('setUserDetails', { isLoggedIn: true, username: response.data.user.username, alias: response.data.user.alias, server: response.data.user.server, group: response.data.user.group })
      }).catch((error) => {
        if (error.response && error.response.data.message === 'Authentification Failed') localStorage.removeItem('fil_jwt')
        this.$store.dispatch('setUserDetails', { isLoggedIn: false })
        return error
      })
  }
}
</script>

<style lang="stylus">
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@300;400;500;700&family=Sacramento&display=swap');

*
  box-sizing border-box
body
  margin 0
  height 100%
  overflow auto
  background linear-gradient(to top, #513f6e, #975a74)
  overflow-y auto
  background-repeat no-repeat
  background-attachment fixed
#app
  font-family: 'Noto Sans TC', Helvetica, Arial, sans-serif;
  // -webkit-font-smoothing antialiased
  // -moz-osx-font-smoothing grayscale
  color #555
  text-align center
  padding-bottom 100px
  padding-top 0

  .fil-rounded-box
    background white
    border 2px solid #e4a5bf
    border-radius 10px
    font-size 1rem

  .main-header
    background white
    color #666
    font-size 2rem
    padding 5px 20px
    // font-weight 300
    color #975a74
    z-index 10
    box-shadow 0 2px 3px 0 rgba(0,0,0,0.14)
    font-family: 'Sacramento', cursive;

  .last-update
    color #fff
    margin 5px 0

    .date
      color #e4a5bf
      font-weight 500

  .footer
    margin 0 auto
    display flex
    flex-direction row
    justify-content center
    align-items flex-start
    flex-wrap wrap
    max-width 700px
    padding 0 20px

    .info, .game-links
      flex: 1
      padding 10px 10px
      text-align left
      color white
      font-size 0.7rem
      line-height 180%
      min-width 200px

      a
        color white

      .title
        font-weight bold
        margin-bottom 5px

      ul
        padding 0 1rem
        margin 0

.modal-container
  position: fixed
  top 0
  left 0
  right 0
  bottom 0
  // background rgba(0,0,0,0.7)
  display flex
  justify-content center
  align-items flex-start
  padding 30px
  z-index 1000
  padding-top 20vh
  .overlay
    position: fixed
    top 0
    left 0
    right 0
    bottom 0
    background rgba(0,0,0,0.7)
  .modal
    background white
    border 2px solid #e4a5bf
    border-radius 10px
    font-size 1rem
    padding 10px
    width 100%
    min-height 150px
    max-width 500px
    z-index 1002

.dispatch-time-picker
  width: 100%
  padding 6px
  border 1px solid #955a74
  border-radius 3px

button
  cursor pointer

.swal-modal
  animation none !important
  font-family: 'Noto Sans TC', Helvetica, Arial, sans-serif;

.v-toast {
  padding 3em 0 !important
  font-family: 'Noto Sans TC', Helvetica, Arial, sans-serif;
}

@media (max-width: 500px)
  .swal-modal {
      width: calc(100% - 80px);
  }
</style>
