import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Partners from '../views/Partners.vue'
import Group from '../views/Group.vue'
import Settings from '../views/Settings.vue'
import LogOut from '../views/LogOut.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      onlyNotAuth: true
    }
  },
  {
    path: '/partners',
    name: 'Partners',
    component: Partners,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/group',
    name: 'Group',
    component: Group,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/outfit',
    beforeEnter (to, from, next) {
      location.href = `${to.query.o}/?t=${to.query.t}`
    }
  },
  {
    path: '/logout',
    component: LogOut
  }
]

const router = new VueRouter({
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.fil_jwt === 'null') localStorage.removeItem('fil_jwt')
    if (localStorage.fil_jwt == null) {
      next({
        path: '/'
      })
    } else {
      next()
    }
  } else if (to.matched.some(record => record.meta.onlyNotAuth)) {
    if (localStorage.fil_jwt == null) {
      next()
    } else {
      let urlRoute = 'https://outfit.flippedinlove.app'
      switch (to.query.origin) {
        case 'fil-outfit-explorer.web.app':
          urlRoute = 'https://fil-outfit-explorer.web.app'
          break
        // case 'localhost:8081':
        //   urlRoute = 'http://localhost:8081'
        //   break
        default:
          urlRoute = 'https://outfit.flippedinlove.app'
          break
      }
      next({
        path: to.query.path && to.query.path.toLowerCase() === 'outfit' ? `/outfit?t=${localStorage.fil_jwt}&o=${urlRoute}` : '/partners'
      })
    }
  } else {
    next()
  }
})

export default router
