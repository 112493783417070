<template lang='pug'>
  .first-time
    .create-account.fil-rounded-box
      .title Create your account
      form(@submit.prevent="signup")
        .fields
          .form-group(:class="{ 'form-group--error': $v.serverNumber.$error }")
            label.server Pick your server
            .form-row
              select.server-location(v-model="serverLocation")
                option(value="asia") Asia
                option(value="america") America
              select.server-number(v-model="serverNumber")
                option(v-for="server in serverCounts[serverLocation]" :value="server") S{{server}}
            .error(v-if="$v.serverNumber.$error && !$v.serverNumber.required") This field is required
          .form-group(:class="{ 'form-group--error': $v.username.$error }")
            label.signup-username(for="signup-username") What name do you go by in the game?
            .description This will be your username
            input#signup-username(type="text" v-model="username")
            .error(v-if="$v.username.$error && !$v.username.allowedCharacters") Username can only contain letters, numbers, and some special characters (. - _)
            .error(v-if="$v.username.$error && !$v.username.required") This field is required
            .error(v-if="$v.username.$error && (!$v.username.minLength || !$v.username.maxLength)")
              | Username must be between {{$v.username.$params.minLength.min}} and {{$v.username.$params.maxLength.max}} characters long.
          .form-group(:class="{ 'form-group--error': $v.password.$error }")
            label.signup-password(for="signup-password") Set a password for your account
            input#signup-password(type="password" v-model="password")
            .error(v-if="$v.password.$error && !$v.password.required") This field is required
            .error(v-if="$v.password.$error && (!$v.password.minLength || !$v.password.maxLength)")
              | Password must be between {{$v.password.$params.minLength.min}} and {{$v.password.$params.minLength.max}} characters long.
          .form-group(:class="{ 'form-group--error': $v.confirmPassword.$error }")
            label.confirm-password(for="confirm-password") Enter your password again
            input#confirm-password(type="password" v-model="confirmPassword")
            .error(v-if=" $v.confirmPassword.$error && !$v.confirmPassword.sameAsPassword") Passwords don't match
        .form-group.submit-container
          button(type="submit") Sign Up
    .separator-or or
    .login.fil-rounded-box
      .title Sign into an existing account
      form(@submit.prevent="login")
        .fields
          .form-group
            label.username(for="username") Username
            input#username(type="text" v-model="loginUsername")
          .form-group
            label.password(for="password") Password
            input#password(type="password" v-model="loginPassword")
        .form-group.submit-container
          button(type="submit") Sign In
</template>

<script>
import { required, minLength, maxLength, sameAs } from 'vuelidate/lib/validators'
import swal from 'sweetalert'
import XRegExp from 'xregexp'

export default {
  name: 'FirstTime',
  data () {
    return {
      serverLocation: 'america',
      serverCounts: {
        asia: 200,
        america: 30
      },
      serverNumber: null,
      username: null,
      password: null,
      confirmPassword: null,
      loginUsername: null,
      loginPassword: null,
      routeToOutfit: false,
      urlRoute: 'https://outfit.flippedinlove.app'
    }
  },
  validations: {
    username: {
      required,
      minLength: minLength(4),
      maxLength: maxLength(25),
      // allowedCharacters: (value) => (/^[0-9a-zA-Z_.-]+$/.test(value))
      allowedCharacters: (value) => {
        const userRegex = XRegExp('^(\\pL|\\pN|_|\\.|-)+$')
        return userRegex.test(value)
      }
    },
    password: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(25)
    },
    confirmPassword: {
      sameAsPassword: sameAs('password')
    },
    serverNumber: {
      required
    }
  },
  mounted () {
    this.$v.$reset()
    console.log(this.$route.query.path)
    if (this.$route.query.path && this.$route.query.path.toLowerCase() === 'outfit') {
      this.routeToOutfit = true
      switch (this.$route.query.origin) {
        case 'fil-outfit-explorer.web.app':
          this.urlRoute = 'https://fil-outfit-explorer.web.app'
          break
        // case 'localhost:8081':
        //   this.urlRoute = 'http://localhost:8081'
        //   break
        default:
          this.urlRoute = 'https://outfit.flippedinlove.app'
          break
      }
    } else {
      this.routeToOutfit = false
    }
  },
  methods: {
    async signup () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
      } else {
        // do your submit logic here
        this.submitStatus = 'PENDING'
        this.$http.post('/users/register', {
          serverLocation: this.serverLocation,
          serverNumber: this.serverNumber,
          username: this.username,
          password: this.password
        })
          .then((response) => {
            this.submitStatus = 'OK'
            const token = response.data.token
            if (token) {
              localStorage.setItem('fil_jwt', token)
              this.updateLoginStatus()
              this.$toast.open({
                message: 'Account created!',
                type: 'success',
                position: 'bottom',
                duration: 3000
              })
              if (this.routeToOutfit) this.$router.push(`/outfit?t=${token}&o=${this.urlRoute}`)
              else this.$router.push('/partners')
            } else {
              swal('Error', 'There was a problem signing up.', 'error')
              this.submitStatus = 'ERROR'
            }
          })
          .catch((error) => {
            if (error.response && error.response.data.message) {
              swal('Error', error.response.data.message, 'error')
            } else swal('Error', 'There was a problem signing up.', 'error')
            this.submitStatus = 'ERROR'
          })
      }
    },
    login () {
      // this.$router.push('/partners')
      this.submitStatus = 'PENDING'
      this.$http.post('/users/login', {
        username: this.loginUsername,
        password: this.loginPassword
      })
        .then((response) => {
          this.submitStatus = 'OK'
          const token = response.data.token
          if (token) {
            localStorage.setItem('fil_jwt', token)
            this.updateLoginStatus()
            // swal({
            //   title: 'Success',
            //   text: 'Login Successful',
            //   icon: 'success'
            // }).then(() => {
            //   this.$router.push('/partners')
            // })
            this.$toast.open({
              message: 'Login Successful',
              type: 'success',
              position: 'bottom',
              duration: 3000
            })
            if (this.routeToOutfit) this.$router.push(`/outfit?t=${token}&o=${this.urlRoute}`)
            else this.$router.push('/partners')
          } else {
            swal('Error', 'There was a problem logging in.', 'error')
            this.submitStatus = 'ERROR'
          }
        })
        .catch((error) => {
          swal('Error', 'There was a problem logging in.', 'error')
          this.submitStatus = 'ERROR'
          return error
        })
    },
    updateLoginStatus () {
      const filJwt = localStorage.fil_jwt
      this.$http.get('/users/me/', { headers: { Authorization: `Bearer ${filJwt}` } })
        .then((response) => {
          this.$store.dispatch('setUserDetails', { isLoggedIn: true, username: response.data.user.username, alias: response.data.user.alias, server: response.data.user.server, group: response.data.user.group })
        }).catch((error) => {
          if (error.response && error.response.data.message === 'Authentification Failed') localStorage.removeItem('fil_jwt')
          this.$store.dispatch('setUserDetails', { isLoggedIn: false })
          return error
        })
    }
  },
  computed: {

  },
  watch: {
    serverLocation: function (val, oldVal) {
      if (val !== oldVal) {
        this.serverNumber = null
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
  .first-time

    .title
      color #d29bb3
      font-size 2rem
      font-family: 'Sacramento', cursive;
      padding 5px

    .separator-or
      color #d29bb3
      font-size 1rem
      padding 5px
      font-weight 300
      text-transform uppercase

    .error
      font-size: 0.8rem
      font-weight 500
      color crimson
      padding 5px

    form
      padding 0 10px
      .form-group
        margin-bottom 8px
        padding-bottom 8px
        border-top 2px dashed #efefef
        text-align left

      label
        font-weight 500
        font-size 0.9rem
        padding 2px 5px
        display block

      .description
        font-weight 400
        font-style italic
        font-size 0.75rem
        display block
        color: #955a74
        padding 0 5px 5px 5px

      input[type=text], input[type=password]
        width: 100%
        padding 6px
        border 1px solid #955a74
        border-radius 3px

      .form-row
        display flex
        flex-direction row
        justify-content center
        align-items center

        select
          padding 6px
          border 1px solid #955a74
          border-radius 3px

        .server-location
          flex: 1
          margin-right: 5px

        .server-number
          min-width 100px

      button
        display: inline-block;
        border-radius: 2px;
        border: none;
        padding: 5px 10px 8px 10px;
        color: #fff;
        text-transform: capitalize;
        font-size: .9rem;
        font-weight: 500;
        background: #513f6e;
        cursor: pointer;
        font-family: Noto Sans TC,Helvetica,Arial,sans-serif;
        margin-top: 10px;
        width: 100%

      .form-group--error
        input[type=text], input[type=password], select
          border 1px solid crimson

</style>
