import Vue from 'vue'
import axios from 'axios'
import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'

import App from './App.vue'
import router from './router'
import store from './store'
import Vuelidate from 'vuelidate'

const base = axios.create({
  baseURL: 'https://api.flippedinlove.app'
  // baseURL: 'http://localhost:3000'
})

Vue.config.productionTip = false
Vue.use(Vuelidate)
Vue.use(VueToast)
Vue.prototype.$http = base

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
