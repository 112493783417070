<template lang='pug'>
  .home
    FirstTime
</template>

<script>
// @ is an alias to /src
import FirstTime from '@/components/FirstTime.vue'
export default {
  name: 'Home',
  components: {
    FirstTime
  }
}
</script>

<style scoped lang="stylus">
  .home
    padding 20px
    max-width 700px
    margin 0 auto
</style>
