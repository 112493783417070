<template lang='pug'>
  .user-settings
    .change-alias.fil-rounded-box
      .title Change Display Name
      form(@submit.prevent="updateAlias")
        .fields
          .form-group(:class="{ 'form-group--error': $v.alias.$error }")
            label.alias(for="alias") Your display name
            input#alias(type="text" v-model="alias")
            .error(v-if="$v.alias.$error && !$v.alias.required") This field is required
            .error(v-if="$v.alias.$error && !$v.alias.minLength")
              | Display Name must be between {{$v.alias.$params.minLength.min}} and {{$v.alias.$params.maxLength.max}} characters long.
        .form-group.submit-container
          button(type="submit") Change Display Name

    .change-alias.fil-rounded-box
      .title Change password
      form(@submit.prevent="updatePassword")
        .fields
          .form-group(:class="{ 'form-group--error': $v.currentPassword.$error }")
            label.password(for="password") Your current password
            input#password(type="password" v-model="currentPassword")
            .error(v-if="$v.currentPassword.$error && !$v.currentPassword.required") This field is required
          .form-group(:class="{ 'form-group--error': $v.newPassword.$error }")
            label.new-password(for="new-password") Your new password
            input#new-password(type="password" v-model="newPassword")
            .error(v-if="$v.newPassword.$error && !$v.newPassword.required") This field is required
            .error(v-if="$v.newPassword.$error && !$v.newPassword.minLength")
              | Password must be between {{$v.newPassword.$params.minLength.min}} and {{$v.newPassword.$params.maxLength.max}} characters long.
          .form-group(:class="{ 'form-group--error': $v.confirmPassword.$error }")
            label.confirm-password(for="confirm-password") Enter your new password again
            input#confirm-password(type="password" v-model="confirmPassword")
            .error(v-if=" $v.confirmPassword.$error && !$v.confirmPassword.sameAsPassword") Passwords don't match
        .form-group.submit-container
          button(type="submit") Change Password
</template>

<script>
import { required, minLength, maxLength, sameAs } from 'vuelidate/lib/validators'
import swal from 'sweetalert'

export default {
  name: 'FirstTime',
  data () {
    return {
      currentPassword: null,
      newPassword: null,
      confirmPassword: null,
      alias: null
    }
  },
  validations: {
    currentPassword: {
      required
    },
    newPassword: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(25)
    },
    confirmPassword: {
      sameAsPassword: sameAs('newPassword')
    },
    alias: {
      required,
      minLength: minLength(1),
      maxLength: maxLength(25)
    }
  },
  mounted () {
    this.$v.$reset()
    this.alias = this.stateAlias
  },
  computed: {
    stateAlias: function () {
      console.log(this.$store.state.alias)
      return this.$store.state.alias
    }
  },
  watch: {
    stateAlias: function (val, oldVal) {
      if (val !== oldVal) {
        this.alias = val
      }
    }
  },
  methods: {
    async updatePassword () {
      const filJwt = localStorage.fil_jwt
      if (!filJwt) {
        this.$store.dispatch('setUserDetails', { isLoggedIn: false })
        this.$router.push('/')
        return
      }

      this.$v.currentPassword.$touch()
      this.$v.newPassword.$touch()
      this.$v.confirmPassword.$touch()
      if (this.$v.currentPassword.$invalid || this.$v.newPassword.$invalid || this.$v.confirmPassword.$invalid) {
        this.submitStatus = 'ERROR'
      } else {
        // do your submit logic here
        this.submitStatus = 'PENDING'
        this.$http.post('/users/me/password', {
          currentPassword: this.currentPassword,
          newPassword: this.newPassword
        }, { headers: { Authorization: `Bearer ${filJwt}` } })
          .then((response) => {
            this.submitStatus = 'OK'

            this.$toast.open({
              message: 'Password updated',
              type: 'success',
              position: 'bottom',
              duration: 3000
            })
            this.$router.go()
          })
          .catch((error) => {
            const errorMessage = (error.response && error.response.data.message) || 'Something went wrong.'
            swal('Error', errorMessage, 'error')
            this.submitStatus = 'ERROR'
          })
      }
    },
    async updateAlias () {
      const filJwt = localStorage.fil_jwt
      if (!filJwt) {
        this.$store.dispatch('setUserDetails', { isLoggedIn: false })
        this.$router.push('/')
        return
      }

      this.$v.alias.$touch()
      if (this.$v.alias.$invalid) {
        this.submitStatus = 'ERROR'
      } else {
        // do your submit logic here
        this.submitStatus = 'PENDING'
        this.$http.post('/users/me/name', {
          alias: this.alias
        }, { headers: { Authorization: `Bearer ${filJwt}` } })
          .then((response) => {
            this.submitStatus = 'OK'

            this.$toast.open({
              message: 'Name updated',
              type: 'success',
              position: 'bottom',
              duration: 3000
            })
            this.$router.go()
          })
          .catch((error) => {
            const errorMessage = (error.response && error.response.data.message) || 'Something went wrong.'
            swal('Error', errorMessage, 'error')
            this.submitStatus = 'ERROR'
          })
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
  .user-settings

    .change-alias
      margin-bottom 20px
    .title
      color #d29bb3
      font-size 2rem
      font-family: 'Sacramento', cursive;
      padding 5px

    .separator-or
      color #d29bb3
      font-size 1rem
      padding 5px
      font-weight 300
      text-transform uppercase

    .error
      font-size: 0.8rem
      font-weight 500
      color crimson
      padding 5px

    form
      padding 0 10px
      .form-group
        margin-bottom 8px
        padding-bottom 8px
        border-top 2px dashed #efefef
        text-align left

      label
        font-weight 500
        font-size 0.9rem
        padding 2px 5px
        display block

      .description
        font-weight 400
        font-style italic
        font-size 0.75rem
        display block
        color: #955a74
        padding 0 5px 5px 5px

      input[type=text], input[type=password]
        width: 100%
        padding 6px
        border 1px solid #955a74
        border-radius 3px

      .form-row
        display flex
        flex-direction row
        justify-content center
        align-items center

        select
          padding 6px
          border 1px solid #955a74
          border-radius 3px

        .server-location
          flex: 1
          margin-right: 5px

        .server-number
          min-width 100px

      button
        display: inline-block;
        border-radius: 2px;
        border: none;
        padding: 5px 10px 8px 10px;
        color: #fff;
        text-transform: capitalize;
        font-size: .9rem;
        font-weight: 500;
        background: #513f6e;
        cursor: pointer;
        font-family: Noto Sans TC,Helvetica,Arial,sans-serif;
        margin-top: 10px;
        width: 100%

      .form-group--error
        input[type=text], input[type=password], select
          border 1px solid crimson

</style>
