<template lang='pug'>
  .user-bar
    .user-row
      .user-details
        span.alias {{ alias }}
        span.username ({{ username }}) -
        span.s-location {{ serverLocation }}
        span.s-number {{ serverNumber }}
      .logout
        span.separator |
        a(href="https://outfit.flippedinlove.app") Outfit Builder
      .logout
        span.separator |
        a(@click="logout") Log out
</template>

<script>
export default {
  name: 'UserBar',
  props: ['onTabSelected'],
  data () {
    return {
    }
  },
  mounted () {

  },
  methods: {
    logout () {
      const filJwt = localStorage.fil_jwt
      if (!filJwt) {
        this.$store.dispatch('setUserDetails', { isLoggedIn: false })
      }
      this.$http.post('/users/logout', {}, { headers: { Authorization: `Bearer ${filJwt}` } })
      localStorage.removeItem('fil_jwt')
      this.$store.dispatch('setUserDetails', { isLoggedIn: false })
      this.$router.push('/')
      this.$toast.open({
        message: 'Logged out successfully',
        type: 'success',
        position: 'bottom',
        duration: 3000
      })
    }
  },
  computed: {
    alias: function () {
      return this.$store.state.alias
    },
    username: function () {
      return this.$store.state.username
    },
    serverLocation: function () {
      return this.$store.state.serverLocation
    },
    serverNumber: function () {
      return `S${this.$store.state.serverNumber}`
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
  .user-bar
    position sticky
    top -1px
    left 0
    right 0
    bottom auto
    // background rgba(255, 255, 255, 0.7)
    z-index 1000
    background #935974
    border-top 1px solid #e8ced9
    box-shadow 0 1px 2px 0 rgba(0,0,0,0.14)

    .user-row
      width 100%
      max-width 700px
      margin 0 auto
      padding 5px 20px
      display flex
      flex-direction row
      align-items center
      font-size 0.8rem
      color white

      .user-details
        flex 1
        text-align right
        span
          &.alias
            font-weight 500
            color #e4b2c8
            margin-right 3px
          &.username
            margin-right 2px
            font-size 0.6rem
          &.s-location, &.s-number
            text-transform capitalize
            margin-right 2px
            font-size 0.6rem

      .logout
        .separator
          margin 0 8px
          color #ccc
        a
          font-size 0.7rem
          font-weight 500
          color #fff
          text-decoration underline
          cursor pointer
          &:hover
            color #cac4f9
</style>
