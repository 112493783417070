import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    username: null,
    alias: null,
    isLoggedIn: false,
    serverNumber: 0,
    serverLocation: null,
    groupId: null,
    groupName: null,
    role: null
  },
  mutations: {
    logIn (state) {
      state.isLoggedIn = true
    },
    logOut (state) {
      state.isLoggedIn = false
    },
    setUsername (state, username) {
      state.username = username
    },
    setUserAlias (state, alias) {
      state.alias = alias
    },
    setServerNumber (state, serverNumber) {
      state.serverNumber = serverNumber
    },
    setServerLocation (state, serverLocation) {
      state.serverLocation = serverLocation
    },
    setGroupInfo (state, { id, name, role }) {
      state.groupId = id
      state.groupName = name
      state.role = role
    }
  },
  actions: {
    setUserDetails ({ commit }, { alias, username, isLoggedIn, server, group = {} }) {
      if (!isLoggedIn) {
        commit('logOut')
        commit('setUsername', null)
        commit('setUserAlias', null)
        commit('setServerNumber', 0)
        commit('setServerLocation', null)
        commit('setGroupInfo', {})
      } else {
        commit('setUsername', username)
        commit('setUserAlias', alias)
        commit('setGroupInfo', group)
        if (!server) {
          commit('setServerNumber', 0)
          commit('setServerLocation', 'America')
        } else {
          const serverLocation = server.charAt(0) === 'u' ? 'America' : 'Asia'
          const serverNumber = parseInt(server.substring(1))
          commit('setServerNumber', serverNumber)
          commit('setServerLocation', serverLocation)
        }
        commit('logIn')
      }
    }
  },
  modules: {
  }
})
