<template lang='pug'>
  .settings
    UserSettings
</template>

<script>
// @ is an alias to /src
import UserSettings from '@/components/UserSettings.vue'
export default {
  name: 'Home',
  components: {
    UserSettings
  }
}
</script>

<style scoped lang="stylus">
  .settings
    padding 20px
    max-width 700px
    margin 0 auto
</style>
