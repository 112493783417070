<template lang='pug'>
  .group-view
    .title {{ group.name ? group.name : 'My Group' }}
    .partners-list.fil-rounded-box
      .title Dispatching Next
      .partner-container(v-if="partners.length > 0" v-for="(partner, i) in partners" :key="`partner-dispatch-${i}`")
        .counter(:style="{width: `${getTimeLeft(partner.dispatchDate || 0)/28 * 100}%`}")
        .partner-row
          .partner-image
            img(:src="require(`@/assets/images/partners/${partner.id}.png`)" :alt="partnerIds[partner.id].name")
            .next
              span Next
          .partner-data
            .name {{partnerIds[partner.id].name}}
            .details
              .info
                .username {{ partner.username }}
                .time-left
                  .time {{getTimeLeft(partner.dispatchDate || 0) > 0 ? `${getTimeLeft(partner.dispatchDate || 0)} Day${getTimeLeft(partner.dispatchDate || 0)  === 1 ? '' : 's'} to go` : `Available for dispatch`}}
                .overall-ability(v-if="partner.ability") Ability: {{formatNumber(partner.ability)}}
              .last-updated
                .date {{formatDate(partner.updatedAt)}}

    .members-list.fil-rounded-box
      .title Group Members
      .member-row(v-for="(member, i) in groupMembers" :key="`member-${i}`")
        .member-role
          img(v-if="member.role === 2" :src="require('@/assets/images/tabicons/crown.svg')" alt="Admin" title="Admin")
        .member-name {{ member.alias }}
        .member-actions(v-if="currentRole === 2 && member.username !== currentUsername.toLowerCase()")
          a.promote.action(v-if="member.role === 1" @click="promoteUser(member.username, member.alias)")
            img(:src="require('@/assets/images/tabicons/mod.svg')" alt="Promote" title="Promote")
          a.demote.action(v-if="member.role === 2" @click="demoteUser(member.username, member.alias)")
            img(:src="require('@/assets/images/tabicons/decrown.svg')" alt="Demote" title="Demote")
          a.delete.action(@click="deleteUser(member.username, member.alias)")
            img(:src="require('@/assets/images/tabicons/delete.svg')" alt="Delete" title="Delete")
      .leave-group.button-container
        a.btn.leave-btn(@click="leaveGroup()") Leave Group

    .invite-box.fil-rounded-box(v-if="currentRole === 2")
      .title Invite Members
      .description Share this invite code with anyone you would like to join the group.
      .invite-code
        input(type="text" readonly :value="group.joinCode" @click="copyCode" ref="joinCodeBox")
      .invite.button-container
        a.btn.leave-btn(@click="generateNewCode()") Generate New Code

</template>

<script>
import swal from 'sweetalert'
import moment from 'moment'
import Partners from '@/data/partners.js'

export default {
  name: 'GroupView',
  data () {
    return {
      group: {},
      groupId: null,
      partners: [],
      partnerIds: {},
      groupMembers: [],
      rolse: {
        1: '',
        2: 'Admin'
      }
    }
  },
  mounted () {
    const partnerIds = {}
    Partners.forEach(partner => {
      partnerIds[partner.secrid] = partner
    })
    this.partnerIds = partnerIds
    this.getGroup()
  },
  methods: {
    formatDate (date) {
      const formattedDate = moment(date)
      return formattedDate.format('ll')
    },
    copyCode () {
      try {
        const textToCopy = this.$refs.joinCodeBox
        textToCopy.select()
        document.execCommand('copy')
        this.$toast.open({
          message: 'Code copied',
          type: 'success',
          position: 'bottom',
          duration: 3000
        })
      } catch (err) {
        return err
      }
    },
    generateNewCode () {
      swal({
        text: 'Do you want to generate a new code? The old code will not be valid anymore.',
        icon: 'info',
        buttons: { yes: true, cancel: 'No' }
      })
        .then(value => {
          if (value !== 'yes') return
          this.$http.post('/groups/generatecode', {
          }, { headers: { Authorization: `Bearer ${localStorage.fil_jwt}` } })
            .then(response => {
              this.$toast.open({
                message: 'Code Generated',
                type: 'success',
                position: 'bottom',
                duration: 3000
              })
              this.getGroup()
            })
            .catch(err => {
              swal({
                text: 'Failed to generate code',
                icon: 'error'
              })
              return err
            })
        })
    },
    promoteUser (username, alias) {
      swal({
        text: `Do you want to promote ${alias} to Admin?`,
        icon: 'info',
        buttons: { yes: true, cancel: 'No' }
      })
        .then(value => {
          if (value !== 'yes') return
          this.$http.post('/groups/promote', {
            username
          }, { headers: { Authorization: `Bearer ${localStorage.fil_jwt}` } })
            .then(response => {
              this.$toast.open({
                message: 'Promoted',
                type: 'success',
                position: 'bottom',
                duration: 3000
              })
              this.getGroup()
            })
            .catch(err => {
              swal({
                text: 'Failed to promote member',
                icon: 'error'
              })
              return err
            })
        })
    },
    demoteUser (username, alias) {
      swal({
        text: `Do you want to demote ${alias} from Admin?`,
        icon: 'info',
        buttons: { yes: true, cancel: 'No' }
      })
        .then(value => {
          if (value !== 'yes') return
          this.$http.post('/groups/demote', {
            username
          }, { headers: { Authorization: `Bearer ${localStorage.fil_jwt}` } })
            .then(response => {
              this.$toast.open({
                message: 'Demoted',
                type: 'success',
                position: 'bottom',
                duration: 3000
              })
              this.getGroup()
            })
            .catch(err => {
              swal({
                text: 'Failed to demote member',
                icon: 'error'
              })
              return err
            })
        })
    },
    deleteUser (username, alias) {
      swal({
        text: `Are you sure want to remove ${alias} from the group?`,
        icon: 'info',
        buttons: { yes: true, cancel: 'No' }
      })
        .then(value => {
          if (value !== 'yes') return
          this.$http.post('/groups/remove', {
            username
          }, { headers: { Authorization: `Bearer ${localStorage.fil_jwt}` } })
            .then(response => {
              this.$toast.open({
                message: 'Removed',
                type: 'success',
                position: 'bottom',
                duration: 3000
              })
              this.getGroup()
            })
            .catch(err => {
              swal({
                text: 'Failed to remove member',
                icon: 'error'
              })
              return err
            })
        })
    },
    leaveGroup (username, alias) {
      swal({
        text: 'Are you sure want to leave the group?',
        icon: 'info',
        buttons: { yes: true, cancel: 'No' }
      })
        .then(value => {
          if (value !== 'yes') return
          this.$http.post('/groups/leave', {
            groupId: this.groupId
          }, { headers: { Authorization: `Bearer ${localStorage.fil_jwt}` } })
            .then(response => {
              this.$toast.open({
                message: 'Done',
                type: 'success',
                position: 'bottom',
                duration: 3000
              })
              this.$router.go(0)
            })
            .catch(err => {
              swal({
                text: 'Failed to leave group',
                icon: 'error'
              })
              return err
            })
        })
    },
    getGroup () {
      this.$http.get('/groups', { headers: { Authorization: `Bearer ${localStorage.fil_jwt}` } })
        .then(response => {
          this.group = response.data.group
          this.groupId = this.group ? this.group.id : null
          if (!this.groupId) return

          const groupMembers = []
          this.group.UserGroups.forEach(u => {
            const user = {}
            user.role = u.role
            user.username = u.User.username
            user.alias = u.User.UserServerData[0].alias
            groupMembers.push(user)
          })
          this.groupMembers = groupMembers

          this.$http.get('/groups/partners', { headers: { Authorization: `Bearer ${localStorage.fil_jwt}` } })
            .then(res => {
              const partners = []
              res.data.partners.forEach(el => {
                if (!el.User) return
                const partner = {}
                partner.ability = el.User.UserPartners[0].overallAbility
                partner.id = el.User.UserPartners[0].partnerId
                partner.dispatchDate = el.User.UserPartners[0].PartnerDispatches && el.User.UserPartners[0].PartnerDispatches[0] ? el.User.UserPartners[0].PartnerDispatches[0].dispatchTime : null
                partner.username = el.User.UserServerData[0].alias
                partner.updatedAt = el.User.UserPartners[0].updatedAt
                partners.push(partner)
              })
              partners.sort((a, b) => a.ability > b.ability ? -1 : 1)
              this.partners = partners
            })
        })
        .catch(err => {
          const errorMessage = (err.response && err.response.data.message) || 'Something went wrong.'
          this.$toast.open({
            message: errorMessage,
            type: 'error',
            position: 'bottom',
            duration: 3000
          })
        })
    },
    getTimeLeft (date) {
      if (!date) return 0
      const cooldownDate = moment(date).add(4, 'weeks')
      const today = moment()
      const diff = cooldownDate.diff(today, 'days')
      return diff < 0 ? 0 : diff
    },
    formatNumber (value) {
      if ((('' + value).length - 1) < 6) return value
      if (value > 1000000000000) return '999B+'
      const suffixes = ['', '', 'M', 'B']
      const suffixNum = Math.floor((('' + value).length - 1) / 3)
      let shortValue = parseFloat((suffixNum !== 0 ? (value / Math.pow(1000, suffixNum)) : value).toPrecision(3))
      if (shortValue % 1 !== 0) {
        shortValue = shortValue.toFixed(2)
      }
      return shortValue + suffixes[suffixNum]
    }
  },
  computed: {
    currentRole: function () {
      return this.$store.state.role
    },
    currentUsername: function () {
      return this.$store.state.username
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
  .group-view

    .title
      color #d29bb3
      font-size 2rem
      font-family: 'Sacramento', cursive;
      padding 5px
      margin-bottom 5px

    .invite-box
      padding 10px
      padding-top 0
      margin-bottom 20px
      .invite-code
        padding-top 15px
        width 100%
        input
          width 100%
          padding 10px
          text-align center
          font-weight 500
          font-size 1.2rem
          color: #935974

    .members-list
      padding 10px
      padding-top 0
      margin-bottom 20px

      .member-row
        display flex
        flex-direction row
        align-items center
        padding 10px
        border 1px solid #b6c6ff
        margin-bottom 10px
        box-shadow  0px 0px 0 1px #ccc

        .member-name
          flex 1
          text-align left
          font-weight: 500;
          font-size: 0.9rem;
          color: #555;
          margin-left 10px
        .member-role
          width 20px
          display flex
          flex-direction row
          align-items center
          img
            height 20px

        .member-actions
          display flex
          flex-direction row
          align-items center
          .action
            display flex
            justify-items center
            align-items center
            margin-left 10px
            cursor pointer
          img
            height 26px

    .partners-list
      padding 10px
      padding-top 0
      margin-bottom 20px

  .partner-container
    position relative
    .counter
      background-color #e6e8f5
      position absolute
      top 0
      left 0
      bottom 0
      border-radius 5px 0 0 5px
      z-index 0
      overflow: hidden
      &:before
        content:'';
        top:0;
        bottom: 0
        left: 0
        width: 100vw;
        position: absolute;
        z-index:1;
        background-image: linear-gradient(to right, #e3f2fd, #e1bee7)
      &:after
        content:'';
        top:0;
        transform:translateX(100%) rotate(45deg);
        width:50%;
        top -200px
        bottom -200px
        position: absolute;
        z-index:2;
        animation: slide 5s infinite;
        background-image: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,.5) 40%, rgba(255,255,255,0) 99%, rgba(255,255,255,0) 100%)

  @keyframes slide
    0% {transform:translateX(-400%) rotate(45deg);}
    50% {transform:translateX(400%) rotate(45deg);}
    100% {transform:translateX(400%) rotate(45deg);}

  .partner-row
    position relative
    padding 5px
    display flex
    flex-direction row
    border 2px solid #b6c6ff
    margin-bottom 10px
    box-shadow  0px 0px 0 1px #ccc
    .partner-image
      position:relative
      background linear-gradient(to bottom, #ffecb3, rgba(255,236,179,0%))
      img
        height 100px
        border 2px solid #ffecb3
        box-shadow  0px 0px 0 1px #ccc, 3px 3px #ddd
        z-index 1

    .partner-data
      display flex
      flex-direction column
      flex: 1
      align-items flex-start
      height 100px
      .name
        width: 100%
        background linear-gradient(to right, #a1b4f5, #a1b4f5 40%, rgba(161,180,245,.18))
        text-align left
        padding 2px 15px
        color white
        font-weight 500
        font-size 0.9rem
        position relative
      .details
        padding 5px 5px 5px 15px
        font-size 0.8rem
        flex 1
        width: 100%
        text-align left
        display flex
        flex-direction column
        .info
          flex: 1
          color: #666
          font-weight 400
          font-size 0.70rem
          line-height 180%
        .action
          display flex
          flex-direction row
          justify-content flex-end
          button
            color white
            font-size 0.76rem
            border 1px solid white
            box-shadow 0px 0px 0 1px #f06292
            background linear-gradient(45deg, #f06292, #f062be)
            padding 3px 5px
            font-weight 500
            &:active
              background #ba68c8 !important
              box-shadow 0px 0px 0 1px #ba68c8 !important
            &.dispatch-next
              box-shadow 0px 0px 0 1px #2196f3
              background linear-gradient(45deg, #2196f3, #9262f0)
              margin-right 10px
            &.cancel
              box-shadow 0px 0px 0 1px crimson
              background linear-gradient(45deg, #f06292, crimson)
  .next
    position absolute
    right 2px
    top 2px
    display flex
    align-items center
    color: white
    span
      font-size 0.65rem
      font-weight 500
      background #9c27b0
      padding 0 5px
      display block
      text-transform uppercase

  .action
    button
      margin-right 10px
      &:last-child
        margin-right 0

  .time-left
    position relative
    width 100%
    border-radius 5px
    // border 1px solid #e4b2c8
    .time
      z-index 2
      position relative
      top 0
      left 0
      bottom 0
      right 0
      font-size 0.7rem
      font-weight 500
  .overall-ability
    font-size 0.7rem
    font-weight 500
  .last-updated
    text-align right
    color #999
    font-size 0.6rem
    font-weight 500

  .username
    font-size 0.8rem
    font-weight 500
    color #975a74

  .button-container
    display flex
    flex-direction row
    width 100%
    justify-content center
    align-items center
    .btn
      flex 1
      color white
      font-weight 500
      font-size 0.9rem
      padding 10px 20px
      background linear-gradient(45deg, #2196f3, #9262f0);
      margin 15px 1px
      box-shadow 0px 0px 0 2px #2196f3
      border 2px solid #ffffff
      cursor pointer
      text-align: center
      &:active
        background #ba68c8 !important
        box-shadow 0px 0px 0 2px #ba68c8 !important

</style>
