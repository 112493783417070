<template lang='pug'>
  .nogroup
    .group-container.fil-rounded-box
      .title My Group
      .button-container
        .btn.create-group(@click="createGroup()")
          img.btn-img(:src="require('@/assets/images/tabicons/create.svg')")
          .text Create Group
        .btn.join-group(@click="joinGroup()")
          img.btn-img(:src="require('@/assets/images/tabicons/join.svg')")
          .text Join Group

</template>

<script>
import swal from 'sweetalert'

export default {
  name: 'NoGroup',
  data () {
    return {
    }
  },
  mounted () {

  },
  methods: {
    createGroup () {
      swal({
        title: 'Create a New Group',
        text: 'Enter a name for the group',
        content: 'input',
        button: {
          text: 'Create Group',
          closeModal: false
        }
      })
        .then(name => {
          if (!name) return
          this.$http.post('/groups/new', { name }, { headers: { Authorization: `Bearer ${localStorage.fil_jwt}` } })
            .then(response => {
              swal.stopLoading()
              swal.close()
              this.$toast.open({
                message: 'Group created!',
                type: 'success',
                position: 'bottom',
                duration: 3000
              })
              this.$router.go(0)
            })
            .catch(err => {
              swal('Oh no!', 'Failed to create group!', 'error')
              return err
            })
        })
    },
    joinGroup () {
      swal({
        title: 'Join Group',
        text: 'Enter the code for the group',
        content: 'input',
        button: {
          text: 'Join Group',
          closeModal: false
        }
      })
        .then(code => {
          if (!code) return
          this.$http.post('/groups/join', { code }, { headers: { Authorization: `Bearer ${localStorage.fil_jwt}` } })
            .then(response => {
              swal.stopLoading()
              swal.close()
              this.$toast.open({
                message: 'Joined Group!',
                type: 'success',
                position: 'bottom',
                duration: 3000
              })
              this.$router.go(0)
            })
            .catch(err => {
              swal('Oh no!', 'Failed to join group!', 'error')
              return err
            })
        })
    }
  },
  computed: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
  .nogroup
    .title
      color #d29bb3
      font-size 2rem
      font-family: 'Sacramento', cursive;
      padding 5px

    .group-container
      padding 0 10px
      padding-bottom 20px

    .button-container
      display flex
      flex-direction column
      width 100%
      justify-content center
      align-items center
      padding 10px 0
      .btn
        display flex
        flex-direction row
        align-items center
        width 180px
        color white
        font-weight 500
        font-size 0.9rem
        padding 10px 20px
        background linear-gradient(45deg, #2196f3, #9262f0);
        margin 15px 0
        box-shadow 0px 0px 0 2px #2196f3
        border 2px solid #ffffff
        cursor pointer
        img
          height 24px
          margin-right 15px
        .text
          flex 1
          text-align left
        &:active
          background #ba68c8 !important
          box-shadow 0px 0px 0 2px #ba68c8 !important

</style>
