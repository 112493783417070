<template lang='pug'>
  .group
    NoGroup(v-if="!groupId")
    GroupView(v-if="groupId")
</template>

<script>
// @ is an alias to /src
import NoGroup from '@/components/NoGroup.vue'
import GroupView from '@/components/GroupView.vue'
export default {
  name: 'Group',
  components: {
    NoGroup,
    GroupView
  },
  computed: {
    groupId: function () {
      return this.$store.state.groupId
    }
  }
}
</script>

<style scoped lang="stylus">
  .group
    padding 20px
    max-width 700px
    margin 0 auto
</style>
