/* eslint-disable */
export default [{
  "bookid25": 0,
  "name": "Edward",
  "bookid21": 0,
  "type": 3,
  "bookid1": 5,
  "bookid18": 0,
  "bookid24": 0,
  "bookid19": 0,
  "bookid20": 0,
  "bookid4": 18,
  "bookid23": 0,
  "bookid11": 0,
  "sortid": 119,
  "bookid28": 0,
  "bookid29": 0,
  "bookid13": 0,
  "des": "{\"age\":\"29\",\"height\":\"181cm\",\"weight\":\"70kg\",\"constellation\":\"Gemini\",\"identity\":\"Crown Prince of England\",\"des\":\"He is the successor to the throne who has high hopes. He treats people kindly and shows the image of a gentleman and elegance to the outside world. At the same time, he constantly uses his influence to touch the authority of the throne.\"} ",
  "bookid10": 0,
  "bookid22": 0,
  "bookid15": 0,
  "getway": "\"Best Partner\" - Exotic Charm",
  "label": "[6,8,9,25,28] ",
  "bookid17": 0,
  "bookid14": 0,
  "bookid26": 0,
  "secrid": 200605,
  "bookid27": 0,
  "bookid12": 0,
  "bookid6": 28,
  "bookid16": 0,
  "bookid30": 0,
  "bookid7": 31,
  "bookid8": 40,
  "bookid2": 7,
  "spec": "[3,1]",
  "bookid9": 46,
  "bookid3": 14,
  "bookid5": 27,
  "bookid0": 2
}, {
  "bookid25": 0,
  "name": "John",
  "bookid21": 0,
  "type": 3,
  "bookid1": 5,
  "bookid18": 0,
  "bookid24": 0,
  "bookid19": 0,
  "bookid20": 0,
  "bookid4": 16,
  "bookid23": 0,
  "bookid11": 0,
  "sortid": 118,
  "bookid28": 0,
  "bookid29": 0,
  "bookid13": 0,
  "des": "{\"age\":\"25\",\"height\":\"178cm\",\"weight\":\"65kg\",\"constellation\":\"Scorpio\",\"identity\":\"Mathematician\",\"des\":\"The youngest Fields Medal winner. At the top of the academic pyramid. Digital geometry is as simple as a toy in his hand. He is more interested in geometric conjecture than secular conjecture.\"}",
  "bookid10": 0,
  "bookid22": 0,
  "bookid15": 0,
  "getway": "\"Best Partner\" - Exotic Charm",
  "label": "[12,16,19,21,23] ",
  "bookid17": 0,
  "bookid14": 0,
  "bookid26": 0,
  "secrid": 200604,
  "bookid27": 0,
  "bookid12": 0,
  "bookid6": 31,
  "bookid16": 0,
  "bookid30": 0,
  "bookid7": 33,
  "bookid8": 40,
  "bookid2": 7,
  "spec": "[3,1]",
  "bookid9": 41,
  "bookid3": 15,
  "bookid5": 27,
  "bookid0": 2
}, {
  "bookid25": 0,
  "name": "Adam ",
  "bookid21": 0,
  "type": 3,
  "bookid1": 5,
  "bookid18": 0,
  "bookid24": 0,
  "bookid19": 0,
  "bookid20": 0,
  "bookid4": 15,
  "bookid23": 0,
  "bookid11": 0,
  "sortid": 117,
  "bookid28": 0,
  "bookid29": 0,
  "bookid13": 0,
  "des": "{\"age\":\"33\",\"height\":\"181cm\",\"weight\":\"67kg\",\"constellation\":\"Cancer\",\"identity\":\"Mafia\",\"des\":\"The successor of the Sopranos, well dressed and hidden in the aristocracy. But when he takes off his disguise and holds his gun tightly, he can take anyone's life mercilessly.\"}",
  "bookid10": 0,
  "bookid22": 0,
  "bookid15": 0,
  "getway": "\"Best Partner\" - Exotic Charm",
  "label": "[11,13,22,29,31] ",
  "bookid17": 0,
  "bookid14": 0,
  "bookid26": 0,
  "secrid": 200603,
  "bookid27": 0,
  "bookid12": 0,
  "bookid6": 31,
  "bookid16": 0,
  "bookid30": 0,
  "bookid7": 33,
  "bookid8": 40,
  "bookid2": 7,
  "spec": "[3,1]",
  "bookid9": 42,
  "bookid3": 14,
  "bookid5": 28,
  "bookid0": 2
}, {
  "bookid25": 0,
  "name": "Mansour",
  "bookid21": 0,
  "type": 3,
  "bookid1": 3,
  "bookid18": 0,
  "bookid24": 0,
  "bookid19": 0,
  "bookid20": 0,
  "bookid4": 15,
  "bookid23": 0,
  "bookid11": 0,
  "sortid": 116,
  "bookid28": 0,
  "bookid29": 0,
  "bookid13": 0,
  "des": "{\"age\":\"28\",\"height\":\"182cm\",\"weight\":\"70kg\",\"constellation\":\"Sagittarius\",\"identity\":\"Noble\",\"des\":\"As a Middle Eastern aristocrat, he received elite education from an early age. He dabbled in politics and business, but few people knew that he was more keen on raising lions.\"}",
  "bookid10": 0,
  "bookid22": 0,
  "bookid15": 0,
  "getway": "\"Best Partner\" - Exotic Charm",
  "label": "[5,9,16,24,27] ",
  "bookid17": 0,
  "bookid14": 0,
  "bookid26": 0,
  "secrid": 200602,
  "bookid27": 0,
  "bookid12": 0,
  "bookid6": 31,
  "bookid16": 0,
  "bookid30": 0,
  "bookid7": 33,
  "bookid8": 40,
  "bookid2": 7,
  "spec": "[3,1]",
  "bookid9": 42,
  "bookid3": 14,
  "bookid5": 29,
  "bookid0": 2
}, {
  "bookid25": 0,
  "name": "Yuechuan",
  "bookid21": 0,
  "type": 3,
  "bookid1": 3,
  "bookid18": 0,
  "bookid24": 0,
  "bookid19": 0,
  "bookid20": 0,
  "bookid4": 16,
  "bookid23": 0,
  "bookid11": 0,
  "sortid": 115,
  "bookid28": 0,
  "bookid29": 0,
  "bookid13": 0,
  "des": "{\"age\":\"26\",\"height\":\"176cm\",\"weight\":\"63kg\",\"constellation\":\"Aries\",\"identity\":\"Zen Monk\",\"des\":\"A highly respected monk. Although young, he is regarded as the future abbot of the temple. For many years, he has been practicing devoutly and ignoring worldly things.\"}",
  "bookid10": 0,
  "bookid22": 0,
  "bookid15": 0,
  "getway": "\"Best Partner\" - Exotic Charm",
  "label": "[1,15,19,30,34] ",
  "bookid17": 0,
  "bookid14": 0,
  "bookid26": 0,
  "secrid": 200601,
  "bookid27": 0,
  "bookid12": 0,
  "bookid6": 31,
  "bookid16": 0,
  "bookid30": 0,
  "bookid7": 33,
  "bookid8": 40,
  "bookid2": 7,
  "spec": "[3,1]",
  "bookid9": 41,
  "bookid3": 14,
  "bookid5": 29,
  "bookid0": 2
}, {
  "bookid25": 0,
  "name": "Bai Yin",
  "bookid21": 0,
  "type": 1,
  "bookid1": 3,
  "bookid18": 0,
  "bookid24": 0,
  "bookid19": 0,
  "bookid20": 0,
  "bookid4": 18,
  "bookid23": 0,
  "bookid11": 0,
  "sortid": 114,
  "bookid28": 0,
  "bookid29": 0,
  "bookid13": 0,
  "des": "{\"age\":\"Unknown\",\"height\":\"167cm\",\"weight\":\"48kg\",\"constellation\":\"Virgo\",\"identity\":\"Divine Being\",\"des\":\"A classical character in oriental divine films, born as a half divine being, she has the body of an immortal, but struggles still with the manly desires of mortals.\"}",
  "bookid10": 0,
  "bookid22": 0,
  "bookid15": 0,
  "getway": "\"Best Partner\" - Divine Five",
  "label": "[15,21,27,30,32]",
  "bookid17": 0,
  "bookid14": 0,
  "bookid26": 0,
  "secrid": 200505,
  "bookid27": 0,
  "bookid12": 0,
  "bookid6": 27,
  "bookid16": 0,
  "bookid30": 0,
  "bookid7": 31,
  "bookid8": 41,
  "bookid2": 7,
  "spec": "[1,2]",
  "bookid9": 0,
  "bookid3": 15,
  "bookid5": 24,
  "bookid0": 1
}, {
  "bookid25": 0,
  "name": "East Monarch",
  "bookid21": 0,
  "type": 1,
  "bookid1": 4,
  "bookid18": 0,
  "bookid24": 0,
  "bookid19": 0,
  "bookid20": 0,
  "bookid4": 16,
  "bookid23": 0,
  "bookid11": 0,
  "sortid": 113,
  "bookid28": 0,
  "bookid29": 0,
  "bookid13": 0,
  "des": "{\"age\":\"33\",\"height\":\"173cm\",\"weight\":\"52kg\",\"constellation\":\"Scorpio\",\"identity\":\"Cult Leader\",\"des\":\"A classical character in oriental divine films, a strange cult leader, spreading convincing religious theories, controlling them under his grip.\"}",
  "bookid10": 0,
  "bookid22": 0,
  "bookid15": 0,
  "getway": "\"Best Partner\" - Divine Five",
  "label": "[5,14,25,29,31]",
  "bookid17": 0,
  "bookid14": 0,
  "bookid26": 0,
  "secrid": 200504,
  "bookid27": 0,
  "bookid12": 0,
  "bookid6": 30,
  "bookid16": 0,
  "bookid30": 0,
  "bookid7": 41,
  "bookid8": 43,
  "bookid2": 5,
  "spec": "[1,2]",
  "bookid9": 0,
  "bookid3": 15,
  "bookid5": 24,
  "bookid0": 2
}, {
  "bookid25": 0,
  "name": "White Wind",
  "bookid21": 0,
  "type": 1,
  "bookid1": 3,
  "bookid18": 0,
  "bookid24": 0,
  "bookid19": 0,
  "bookid20": 0,
  "bookid4": 15,
  "bookid23": 0,
  "bookid11": 0,
  "sortid": 112,
  "bookid28": 0,
  "bookid29": 0,
  "bookid13": 0,
  "des": "{\"age\":\"27\",\"height\":\"183cm\",\"weight\":\"62kg\",\"constellation\":\"Capricorn\",\"identity\":\"Monk\",\"des\":\"A classical character in oriental divine films, here to cleanse the world of all evil spirits and unearthly beings. He follows strict guidelins and is always here to defeat wrongdoers.\"}",
  "bookid10": 0,
  "bookid22": 0,
  "bookid15": 0,
  "getway": "\"Best Partner\" - Divine Five",
  "label": "[22,25,30,33,34]",
  "bookid17": 0,
  "bookid14": 0,
  "bookid26": 0,
  "secrid": 200503,
  "bookid27": 0,
  "bookid12": 0,
  "bookid6": 24,
  "bookid16": 0,
  "bookid30": 0,
  "bookid7": 31,
  "bookid8": 42,
  "bookid2": 5,
  "spec": "[1,2]",
  "bookid9": 0,
  "bookid3": 14,
  "bookid5": 16,
  "bookid0": 2
}, {
  "bookid25": 0,
  "name": "Alone Rider",
  "bookid21": 0,
  "type": 1,
  "bookid1": 3,
  "bookid18": 0,
  "bookid24": 0,
  "bookid19": 0,
  "bookid20": 0,
  "bookid4": 15,
  "bookid23": 0,
  "bookid11": 0,
  "sortid": 111,
  "bookid28": 0,
  "bookid29": 0,
  "bookid13": 0,
  "des": "{\"age\":\"21\",\"height\":\"182cm\",\"weight\":\"63kg\",\"constellation\":\"Scorpio\",\"identity\":\"Monk\",\"des\":\"A classical character in oriental divine films, he never asks, never says. He is always on the way, a lonley long way to go.\"}",
  "bookid10": 0,
  "bookid22": 0,
  "bookid15": 0,
  "getway": "\"Best Partner\" - Divine Five",
  "label": " [12,19,23,26,28]",
  "bookid17": 0,
  "bookid14": 0,
  "bookid26": 0,
  "secrid": 200502,
  "bookid27": 0,
  "bookid12": 0,
  "bookid6": 23,
  "bookid16": 0,
  "bookid30": 0,
  "bookid7": 31,
  "bookid8": 41,
  "bookid2": 4,
  "spec": "[1,2]",
  "bookid9": 0,
  "bookid3": 7,
  "bookid5": 16,
  "bookid0": 1
}, {
  "bookid25": 0,
  "name": "Vixen",
  "bookid21": 0,
  "type": 1,
  "bookid1": 3,
  "bookid18": 0,
  "bookid24": 0,
  "bookid19": 0,
  "bookid20": 0,
  "bookid4": 16,
  "bookid23": 0,
  "bookid11": 0,
  "sortid": 110,
  "bookid28": 0,
  "bookid29": 0,
  "bookid13": 0,
  "des": "{\"age\":\"Unknown\",\"height\":\"182cm\",\"weight\":\"65kg\",\"constellation\":\"Libra\",\"identity\":\"Fox Spirit\",\"des\":\"A classical character in oriental divine films, a fox spirit in a human body, trying to break the ranks to become an immortal being. Thousands of years in waiting has made him unimpressed with all mortal things.\"}",
  "bookid10": 0,
  "bookid22": 0,
  "bookid15": 0,
  "getway": "\"Best Partner\" - Divine Five",
  "label": "[4,18,20,24,29]",
  "bookid17": 0,
  "bookid14": 0,
  "bookid26": 0,
  "secrid": 200501,
  "bookid27": 0,
  "bookid12": 0,
  "bookid6": 30,
  "bookid16": 0,
  "bookid30": 0,
  "bookid7": 41,
  "bookid8": 44,
  "bookid2": 7,
  "spec": "[1,2]",
  "bookid9": 0,
  "bookid3": 15,
  "bookid5": 18,
  "bookid0": 2
}, {
  "bookid25": 0,
  "name": "Kimia",
  "bookid21": 0,
  "type": 3,
  "bookid1": 7,
  "bookid18": 0,
  "bookid24": 0,
  "bookid19": 0,
  "bookid20": 0,
  "bookid4": 16,
  "bookid23": 0,
  "bookid11": 0,
  "sortid": 109,
  "bookid28": 0,
  "bookid29": 0,
  "bookid13": 0,
  "des": "{\"age\":\"22\",\"height\":\"164cm\",\"weight\":\"45kg\",\"constellation\":\"Aquarius\",\"identity\":\"artist\",\"des\":\"Girl Group (14U) One For You's band leader. She is also the song producer of many songs on the team. She knows her team and adjusts the song according to everybody's skills perfectly.\"}",
  "bookid10": 0,
  "bookid22": 0,
  "bookid15": 0,
  "getway": "Girl Group from \"Best Partner\"",
  "label": "[2,9,15,17,29] ",
  "bookid17": 0,
  "bookid14": 0,
  "bookid26": 0,
  "secrid": 200404,
  "bookid27": 0,
  "bookid12": 0,
  "bookid6": 32,
  "bookid16": 0,
  "bookid30": 0,
  "bookid7": 33,
  "bookid8": 41,
  "bookid2": 14,
  "spec": "[3,2]",
  "bookid9": 0,
  "bookid3": 15,
  "bookid5": 28,
  "bookid0": 6
}, {
  "bookid25": 0,
  "name": "Sage",
  "bookid21": 0,
  "type": 3,
  "bookid1": 7,
  "bookid18": 0,
  "bookid24": 0,
  "bookid19": 0,
  "bookid20": 0,
  "bookid4": 17,
  "bookid23": 0,
  "bookid11": 0,
  "sortid": 108,
  "bookid28": 0,
  "bookid29": 0,
  "bookid13": 0,
  "des": "{\"age\":\"21\",\"height\":\"163cm\",\"weight\":\"43kg\",\"constellation\":\"Taurus\",\"identity\":\"artist\",\"des\":\"Girl Group (14U) One For You's rapper role. Her small body contains a lot of energy and can blast up the stage with her powerful raps.\"}",
  "bookid10": 0,
  "bookid22": 0,
  "bookid15": 0,
  "getway": "Girl Group from \"Best Partner\"",
  "label": "[1,3,20,27,34] ",
  "bookid17": 0,
  "bookid14": 0,
  "bookid26": 0,
  "secrid": 200403,
  "bookid27": 0,
  "bookid12": 0,
  "bookid6": 32,
  "bookid16": 0,
  "bookid30": 0,
  "bookid7": 33,
  "bookid8": 42,
  "bookid2": 14,
  "spec": "[3,2]",
  "bookid9": 0,
  "bookid3": 15,
  "bookid5": 28,
  "bookid0": 3
}, {
  "bookid25": 0,
  "name": "Belle",
  "bookid21": 0,
  "type": 3,
  "bookid1": 7,
  "bookid18": 0,
  "bookid24": 0,
  "bookid19": 0,
  "bookid20": 0,
  "bookid4": 18,
  "bookid23": 0,
  "bookid11": 0,
  "sortid": 107,
  "bookid28": 0,
  "bookid29": 0,
  "bookid13": 0,
  "des": "{\"age\":\"22\",\"height\":\"165cm\",\"weight\":\"46kg\",\"constellation\":\"Pisces\",\"identity\":\"artist\",\"des\":\"Girl Group (14U) One For You's main dancer. She uses her body to make the music come alive, her dance moves are legendary.\"}",
  "bookid10": 0,
  "bookid22": 0,
  "bookid15": 0,
  "getway": "Girl Group from \"Best Partner\"",
  "label": "[2,14,22,24,30] ",
  "bookid17": 0,
  "bookid14": 0,
  "bookid26": 0,
  "secrid": 200402,
  "bookid27": 0,
  "bookid12": 0,
  "bookid6": 32,
  "bookid16": 0,
  "bookid30": 0,
  "bookid7": 33,
  "bookid8": 42,
  "bookid2": 14,
  "spec": "[3,2]",
  "bookid9": 0,
  "bookid3": 15,
  "bookid5": 27,
  "bookid0": 3
}, {
  "bookid25": 0,
  "name": "Roxi",
  "bookid21": 0,
  "type": 3,
  "bookid1": 7,
  "bookid18": 0,
  "bookid24": 0,
  "bookid19": 0,
  "bookid20": 0,
  "bookid4": 18,
  "bookid23": 0,
  "bookid11": 0,
  "sortid": 106,
  "bookid28": 0,
  "bookid29": 0,
  "bookid13": 0,
  "des": "{\"age\":\"20\",\"height\":\"162cm\",\"weight\":\"45kg\",\"constellation\":\"Libra\",\"identity\":\"Singer\",\"des\":\"Girl Group (14U) One For You's vocalist, a sweet young girl that is a walking karoake. Although she is not the youngest, but she is the most cared for by members.\"}",
  "bookid10": 0,
  "bookid22": 0,
  "bookid15": 0,
  "getway": "Girl Group from \"Best Partner\"",
  "label": "[3,7,23,26,32] ",
  "bookid17": 0,
  "bookid14": 0,
  "bookid26": 0,
  "secrid": 200401,
  "bookid27": 0,
  "bookid12": 0,
  "bookid6": 32,
  "bookid16": 0,
  "bookid30": 0,
  "bookid7": 33,
  "bookid8": 41,
  "bookid2": 14,
  "spec": "[3,2]",
  "bookid9": 0,
  "bookid3": 16,
  "bookid5": 27,
  "bookid0": 3
}, {
  "bookid25": 0,
  "name": "Gordon",
  "bookid21": 0,
  "type": 4,
  "bookid1": 7,
  "bookid18": 0,
  "bookid24": 0,
  "bookid19": 0,
  "bookid20": 0,
  "bookid4": 15,
  "bookid23": 0,
  "bookid11": 0,
  "sortid": 105,
  "bookid28": 0,
  "bookid29": 0,
  "bookid13": 0,
  "des": "{\"age\":\"31\",\"height\":\"180cm\",\"weight\":\"67kg\",\"constellation\":\"Scorpio\",\"identity\":\"Police Officer\",\"des\":\"A skilled officer always going out of his way to ensure the safety of the people under his protection.\"}",
  "bookid10": 0,
  "bookid22": 0,
  "bookid15": 0,
  "getway": "Industry Elites from \"Best Partner\"",
  "label": "[11,22,23,33]",
  "bookid17": 0,
  "bookid14": 0,
  "bookid26": 0,
  "secrid": 200305,
  "bookid27": 0,
  "bookid12": 0,
  "bookid6": 41,
  "bookid16": 0,
  "bookid30": 0,
  "bookid7": 44,
  "bookid8": 50,
  "bookid2": 11,
  "spec": "[4,1]",
  "bookid9": 0,
  "bookid3": 14,
  "bookid5": 29,
  "bookid0": 1
}, {
  "bookid25": 0,
  "name": "Ray",
  "bookid21": 0,
  "type": 4,
  "bookid1": 5,
  "bookid18": 0,
  "bookid24": 0,
  "bookid19": 0,
  "bookid20": 0,
  "bookid4": 16,
  "bookid23": 0,
  "bookid11": 0,
  "sortid": 104,
  "bookid28": 0,
  "bookid29": 0,
  "bookid13": 0,
  "des": "{\"age\":\"32\",\"height\":\"182cm\",\"weight\":\"72kg\",\"constellation\":\"Gemini\",\"identity\":\"Lawyer\",\"des\":\"An elite lawyer dealing with the most difficult cases possible. He is fair and just and believes that the Law should be respected.\"}",
  "bookid10": 0,
  "bookid22": 0,
  "bookid15": 0,
  "getway": "Industry Elites from \"Best Partner\"",
  "label": "[12,16,31,34]",
  "bookid17": 0,
  "bookid14": 0,
  "bookid26": 0,
  "secrid": 200304,
  "bookid27": 0,
  "bookid12": 0,
  "bookid6": 28,
  "bookid16": 0,
  "bookid30": 0,
  "bookid7": 49,
  "bookid8": 50,
  "bookid2": 11,
  "spec": "[4,1]",
  "bookid9": 0,
  "bookid3": 15,
  "bookid5": 27,
  "bookid0": 1
}, {
  "bookid25": 0,
  "name": "Aze",
  "bookid21": 0,
  "type": 4,
  "bookid1": 5,
  "bookid18": 0,
  "bookid24": 0,
  "bookid19": 0,
  "bookid20": 0,
  "bookid4": 15,
  "bookid23": 0,
  "bookid11": 0,
  "sortid": 103,
  "bookid28": 0,
  "bookid29": 0,
  "bookid13": 0,
  "des": "{\"age\":\"19\",\"height\":\"176cm\",\"weight\":\"61kg\",\"constellation\":\"Libra\",\"identity\":\"Chess Master\",\"des\":\"He may look young, but his mind hides strategies and gameplay worth millions. Usually shy and quiet when not playing.\"}",
  "bookid10": 0,
  "bookid22": 0,
  "bookid15": 0,
  "getway": "Industry Elites from \"Best Partner\"",
  "label": "[12,19,24,30]",
  "bookid17": 0,
  "bookid14": 0,
  "bookid26": 0,
  "secrid": 200303,
  "bookid27": 0,
  "bookid12": 0,
  "bookid6": 28,
  "bookid16": 0,
  "bookid30": 0,
  "bookid7": 40,
  "bookid8": 49,
  "bookid2": 11,
  "spec": "[4,1]",
  "bookid9": 50,
  "bookid3": 14,
  "bookid5": 27,
  "bookid0": 2
}, {
  "bookid25": 0,
  "name": "Reginald",
  "bookid21": 0,
  "type": 4,
  "bookid1": 5,
  "bookid18": 0,
  "bookid24": 0,
  "bookid19": 0,
  "bookid20": 0,
  "bookid4": 28,
  "bookid23": 0,
  "bookid11": 0,
  "sortid": 102,
  "bookid28": 0,
  "bookid29": 0,
  "bookid13": 0,
  "des": "{\"age\":\"21\",\"height\":\"178cm\",\"weight\":\"69kg\",\"constellation\":\"Leo\",\"identity\":\"Tennis Player\",\"des\":\"It's all about keeping the eye on the ball, his eyes are only ever on the ball, don't hope he will look at you!\"}",
  "bookid10": 0,
  "bookid22": 0,
  "bookid15": 0,
  "getway": "Industry Elites from \"Best Partner\"",
  "label": "[13,26,28,32]",
  "bookid17": 0,
  "bookid14": 0,
  "bookid26": 0,
  "secrid": 200302,
  "bookid27": 0,
  "bookid12": 0,
  "bookid6": 44,
  "bookid16": 0,
  "bookid30": 0,
  "bookid7": 49,
  "bookid8": 0,
  "bookid2": 11,
  "spec": "[4,1]",
  "bookid9": 0,
  "bookid3": 24,
  "bookid5": 41,
  "bookid0": 1
}, {
  "bookid25": 0,
  "name": "Jay",
  "bookid21": 0,
  "type": 4,
  "bookid1": 5,
  "bookid18": 0,
  "bookid24": 0,
  "bookid19": 0,
  "bookid20": 0,
  "bookid4": 27,
  "bookid23": 0,
  "bookid11": 0,
  "sortid": 101,
  "bookid28": 0,
  "bookid29": 0,
  "bookid13": 0,
  "des": "{\"age\":\"23\",\"height\":\"181cm\",\"weight\":\"74kg\",\"constellation\":\"Gemini\",\"identity\":\"Esports Player\",\"des\":\"Professional electronic games player, has led his team to many professional league championships.\"}",
  "bookid10": 0,
  "bookid22": 0,
  "bookid15": 0,
  "getway": "Industry Elites from \"Best Partner\"",
  "label": "[21,25,29,31]",
  "bookid17": 0,
  "bookid14": 0,
  "bookid26": 0,
  "secrid": 200301,
  "bookid27": 0,
  "bookid12": 0,
  "bookid6": 49,
  "bookid16": 0,
  "bookid30": 0,
  "bookid7": 50,
  "bookid8": 0,
  "bookid2": 11,
  "spec": "[4,1]",
  "bookid9": 0,
  "bookid3": 18,
  "bookid5": 29,
  "bookid0": 1
}, {
  "bookid25": 50,
  "name": "Qin Tang",
  "bookid21": 41,
  "type": 2,
  "bookid1": 4,
  "bookid18": 33,
  "bookid24": 46,
  "bookid19": 37,
  "bookid20": 40,
  "bookid4": 7,
  "bookid23": 44,
  "bookid11": 21,
  "sortid": 41,
  "bookid28": 0,
  "bookid29": 0,
  "bookid13": 60,
  "des": "{\"age\":\"32\",\"height\":\"180cm\",\"weight\":\"65kg\",\"constellation\":\"Taurus\",\"identity\":\"Actress\",\"des\":\"Famous actor, often playing emperor. He once performed the whole dynasty with superb acting skills. Influenced by history and plays all the year round, his gestures are full of imperial aura.\"}",
  "bookid10": 20,
  "bookid22": 42,
  "bookid15": 28,
  "getway": "VIP 13 Gift",
  "label": "[4,12,25,31,34]",
  "bookid17": 31,
  "bookid14": 27,
  "bookid26": 0,
  "secrid": 200209,
  "bookid27": 0,
  "bookid12": 24,
  "bookid6": 59,
  "bookid16": 29,
  "bookid30": 0,
  "bookid7": 16,
  "bookid8": 18,
  "bookid2": 5,
  "spec": "[2,1]",
  "bookid9": 19,
  "bookid3": 6,
  "bookid5": 11,
  "bookid0": 3
}, {
  "bookid25": 62,
  "name": "Anya",
  "bookid21": 42,
  "type": 1,
  "bookid1": 2,
  "bookid18": 37,
  "bookid24": 50,
  "bookid19": 40,
  "bookid20": 41,
  "bookid4": 7,
  "bookid23": 46,
  "bookid11": 20,
  "sortid": 40,
  "bookid28": 0,
  "bookid29": 0,
  "bookid13": 27,
  "des": "{\"age\":\"26\",\"height\":\"165cm\",\"weight\":\"45kg\",\"constellation\":\"Pisces\",\"identity\":\"Actress\",\"des\":\"Famous Actress, not only beautiful but also remarkably talented. She has a cute face but can play any role, a real talent of the industry!\"} ",
  "bookid10": 18,
  "bookid22": 44,
  "bookid15": 29,
  "getway": "VIP 12 Gift",
  "label": "[4,6,26,32,33] ",
  "bookid17": 33,
  "bookid14": 28,
  "bookid26": 0,
  "secrid": 200208,
  "bookid27": 0,
  "bookid12": 24,
  "bookid6": 59,
  "bookid16": 31,
  "bookid30": 0,
  "bookid7": 14,
  "bookid8": 15,
  "bookid2": 3,
  "spec": "[1,4]",
  "bookid9": 16,
  "bookid3": 5,
  "bookid5": 11,
  "bookid0": 1
}, {
  "bookid25": 0,
  "name": "Oliver",
  "bookid21": 0,
  "type": 2,
  "bookid1": 6,
  "bookid18": 0,
  "bookid24": 0,
  "bookid19": 0,
  "bookid20": 0,
  "bookid4": 59,
  "bookid23": 0,
  "bookid11": 33,
  "sortid": 39,
  "bookid28": 0,
  "bookid29": 0,
  "bookid13": 46,
  "des": "{\"age\":\"27\",\"height\":\"178cm\",\"weight\":\"63kg\",\"constellation\":\"Scorpio\",\"identity\":\"Star\",\"des\":\"An amazing actor that has proved he can play any role. He is has risen to the top of the ranks through his experience and hard work.\"}",
  "bookid10": 28,
  "bookid22": 0,
  "bookid15": 0,
  "getway": "VIP 10 Gift",
  "label": " [4,5,13,21,28] ",
  "bookid17": 0,
  "bookid14": 50,
  "bookid26": 0,
  "secrid": 200207,
  "bookid27": 0,
  "bookid12": 42,
  "bookid6": 16,
  "bookid16": 0,
  "bookid30": 0,
  "bookid7": 23,
  "bookid8": 24,
  "bookid2": 7,
  "spec": "[2,1]",
  "bookid9": 27,
  "bookid3": 11,
  "bookid5": 15,
  "bookid0": 1
}, {
  "bookid25": 0,
  "name": "Shane Ace",
  "bookid21": 0,
  "type": 1,
  "bookid1": 2,
  "bookid18": 0,
  "bookid24": 0,
  "bookid19": 0,
  "bookid20": 0,
  "bookid4": 11,
  "bookid23": 0,
  "bookid11": 41,
  "sortid": 38,
  "bookid28": 0,
  "bookid29": 0,
  "bookid13": 46,
  "des": "{\"age\":\"20\",\"height\":\"174cm\",\"weight\":\"56kg\",\"constellation\":\"Sagittarius\",\"identity\":\"Artist\",\"des\":\"Although appearing somewhat cool, he is actually a easy person to get along with. Accomplished in dance, singing and acting, quickly growing in popularity.\"}",
  "bookid10": 31,
  "bookid22": 0,
  "bookid15": 0,
  "getway": "VIP 8 Gift",
  "label": "[1,2,4,14,33] ",
  "bookid17": 0,
  "bookid14": 0,
  "bookid26": 0,
  "secrid": 200206,
  "bookid27": 0,
  "bookid12": 42,
  "bookid6": 18,
  "bookid16": 0,
  "bookid30": 0,
  "bookid7": 20,
  "bookid8": 27,
  "bookid2": 3,
  "spec": "[1]",
  "bookid9": 28,
  "bookid3": 5,
  "bookid5": 16,
  "bookid0": 1
}, {
  "bookid25": 0,
  "name": "Salsa",
  "bookid21": 0,
  "type": 4,
  "bookid1": 5,
  "bookid18": 0,
  "bookid24": 0,
  "bookid19": 0,
  "bookid20": 0,
  "bookid4": 28,
  "bookid23": 0,
  "bookid11": 0,
  "sortid": 37,
  "bookid28": 0,
  "bookid29": 0,
  "bookid13": 0,
  "des": "{\"age\":\"22\",\"height\":\"167cm\",\"weight\":\"45kg\",\"constellation\":\"Libra\",\"identity\":\"Star\",\"des\":\"Experienced on stage and invited to be a celebrity mentor to help with training of new stars.\"}",
  "bookid10": 0,
  "bookid22": 0,
  "bookid15": 0,
  "getway": "VIP 6 Gift",
  "label": "[2,6,27,28,31] ",
  "bookid17": 0,
  "bookid14": 0,
  "bookid26": 0,
  "secrid": 200205,
  "bookid27": 0,
  "bookid12": 0,
  "bookid6": 44,
  "bookid16": 0,
  "bookid30": 0,
  "bookid7": 50,
  "bookid8": 0,
  "bookid2": 7,
  "spec": "[4,1]",
  "bookid9": 0,
  "bookid3": 16,
  "bookid5": 43,
  "bookid0": 4
}, {
  "bookid25": 0,
  "name": "Ellio",
  "bookid21": 0,
  "type": 2,
  "bookid1": 3,
  "bookid18": 0,
  "bookid24": 0,
  "bookid19": 0,
  "bookid20": 0,
  "bookid4": 19,
  "bookid23": 0,
  "bookid11": 0,
  "sortid": 36,
  "bookid28": 0,
  "bookid29": 0,
  "bookid13": 0,
  "des": "{\"age\":\"21\",\"height\":\"176cm\",\"weight\":\"58kg\",\"constellation\":\"Virgo\",\"identity\":\"Star\",\"des\":\"Started acting from a young age, making him very stable in the business. He is demanding of himself and has been honing his acting skills.\"}",
  "bookid10": 0,
  "bookid22": 0,
  "bookid15": 0,
  "getway": "VIP 4 Gift",
  "label": "[1,5,13,15,27]",
  "bookid17": 0,
  "bookid14": 0,
  "bookid26": 0,
  "secrid": 200204,
  "bookid27": 0,
  "bookid12": 0,
  "bookid6": 29,
  "bookid16": 0,
  "bookid30": 0,
  "bookid7": 42,
  "bookid8": 0,
  "bookid2": 11,
  "spec": "[2,1]",
  "bookid9": 0,
  "bookid3": 15,
  "bookid5": 20,
  "bookid0": 2
}, {
  "bookid25": 0,
  "name": "Laura",
  "bookid21": 0,
  "type": 1,
  "bookid1": 14,
  "bookid18": 0,
  "bookid24": 0,
  "bookid19": 0,
  "bookid20": 0,
  "bookid4": 33,
  "bookid23": 0,
  "bookid11": 0,
  "sortid": 35,
  "bookid28": 0,
  "bookid29": 0,
  "bookid13": 0,
  "des": "{\"age\":\"24\",\"height\":\"170cm\",\"weight\":\"48kg\",\"constellation\":\"Sagittarius\",\"identity\":\"Star\",\"des\":\"There is some mixed praise for her, but she just does what she wants to do without a care in the world.\"}",
  "bookid10": 0,
  "bookid22": 0,
  "bookid15": 0,
  "getway": "VIP 2 Gift",
  "label": "[4,9,20,32] ",
  "bookid17": 0,
  "bookid14": 0,
  "bookid26": 0,
  "secrid": 200203,
  "bookid27": 0,
  "bookid12": 0,
  "bookid6": 5,
  "bookid16": 0,
  "bookid30": 0,
  "bookid7": 0,
  "bookid8": 0,
  "bookid2": 20,
  "spec": "[1]",
  "bookid9": 0,
  "bookid3": 27,
  "bookid5": 40,
  "bookid0": 11
}, {
  "bookid25": 0,
  "name": "Jeong",
  "bookid21": 0,
  "type": 2,
  "bookid1": 19,
  "bookid18": 0,
  "bookid24": 0,
  "bookid19": 0,
  "bookid20": 0,
  "bookid4": 33,
  "bookid23": 0,
  "bookid11": 0,
  "sortid": 34,
  "bookid28": 0,
  "bookid29": 0,
  "bookid13": 0,
  "des": "{\"age\":\"23\",\"height\":\"181cm\",\"weight\":\"60kg\",\"constellation\":\"Leo\",\"identity\":\"Star\",\"des\":\"Cold and quiet, he debuted in Korea before moving internationally. His fans are all over the world, even supporting him from country to country as he travels.\"}",
  "bookid10": 0,
  "bookid22": 0,
  "bookid15": 0,
  "getway": "Top-up 68 VIP Gift",
  "label": "[2,5,13,21] ",
  "bookid17": 0,
  "bookid14": 0,
  "bookid26": 0,
  "secrid": 200202,
  "bookid27": 0,
  "bookid12": 0,
  "bookid6": 0,
  "bookid16": 0,
  "bookid30": 0,
  "bookid7": 0,
  "bookid8": 0,
  "bookid2": 20,
  "spec": "[2,3]",
  "bookid9": 0,
  "bookid3": 32,
  "bookid5": 43,
  "bookid0": 3
}, {
  "bookid25": 0,
  "name": "Natalia",
  "bookid21": 0,
  "type": 1,
  "bookid1": 7,
  "bookid18": 0,
  "bookid24": 0,
  "bookid19": 0,
  "bookid20": 0,
  "bookid4": 42,
  "bookid23": 0,
  "bookid11": 0,
  "sortid": 33,
  "bookid28": 0,
  "bookid29": 0,
  "bookid13": 0,
  "des": "{\"age\":\"32\",\"height\":\"167cm\",\"weight\":\"49kg\",\"constellation\":\"Leo\",\"identity\":\"Star\",\"des\":\"A respected figure in the business, says and does what she wants. She may be straighforward but her directness has also won support.\"}",
  "bookid10": 0,
  "bookid22": 0,
  "bookid15": 0,
  "getway": "$ 0 Purchase",
  "label": "[4,5,22,31] ",
  "bookid17": 0,
  "bookid14": 0,
  "bookid26": 0,
  "secrid": 200201,
  "bookid27": 0,
  "bookid12": 0,
  "bookid6": 0,
  "bookid16": 0,
  "bookid30": 0,
  "bookid7": 0,
  "bookid8": 0,
  "bookid2": 15,
  "spec": "[1,4]",
  "bookid9": 0,
  "bookid3": 29,
  "bookid5": 46,
  "bookid0": 5
}, {
  "bookid25": 0,
  "name": "Ms. Athena",
  "bookid21": 0,
  "type": 1,
  "bookid1": 5,
  "bookid18": 0,
  "bookid24": 0,
  "bookid19": 0,
  "bookid20": 0,
  "bookid4": 19,
  "bookid23": 0,
  "bookid11": 44,
  "sortid": 32,
  "bookid28": 0,
  "bookid29": 0,
  "bookid13": 0,
  "des": "{\"age\":\"40\",\"height\":\"168cm\",\"weight\":\"45kg\",\"constellation\":\"Capricorn\",\"identity\":\"Star\",\"des\":\"One of the most respected individuals in showbiz, she is the idol of many young rising stars.\"}",
  "bookid10": 42,
  "bookid22": 0,
  "bookid15": 0,
  "getway": "Upgrade to \"A - List\" to unlock",
  "label": "[3,15,27,29]",
  "bookid17": 0,
  "bookid14": 0,
  "bookid26": 0,
  "secrid": 200117,
  "bookid27": 0,
  "bookid12": 0,
  "bookid6": 28,
  "bookid16": 0,
  "bookid30": 0,
  "bookid7": 29,
  "bookid8": 31,
  "bookid2": 7,
  "spec": "[1]",
  "bookid9": 41,
  "bookid3": 11,
  "bookid5": 20,
  "bookid0": 3
}, {
  "bookid25": 0,
  "name": "Ferrand",
  "bookid21": 0,
  "type": 4,
  "bookid1": 16,
  "bookid18": 0,
  "bookid24": 0,
  "bookid19": 0,
  "bookid20": 0,
  "bookid4": 45,
  "bookid23": 0,
  "bookid11": 0,
  "sortid": 31,
  "bookid28": 0,
  "bookid29": 0,
  "bookid13": 0,
  "des": "{\"age\":\"46\",\"height\":\"183cm\",\"weight\":\"70kg\",\"constellation\":\"Pisces\",\"identity\":\"Screenwriter\",\"des\":\"A best-selling author and a  first-line screenwriter, best at portraying female characters that are vivid and unconventional.\"}",
  "bookid10": 0,
  "bookid22": 0,
  "bookid15": 0,
  "getway": "Upgrade to “B - List\" to unlock",
  "label": "[12,15,17,25]",
  "bookid17": 0,
  "bookid14": 0,
  "bookid26": 0,
  "secrid": 200116,
  "bookid27": 0,
  "bookid12": 0,
  "bookid6": 0,
  "bookid16": 0,
  "bookid30": 0,
  "bookid7": 0,
  "bookid8": 0,
  "bookid2": 31,
  "spec": "[4]",
  "bookid9": 0,
  "bookid3": 40,
  "bookid5": 46,
  "bookid0": 3
}, {
  "bookid25": 0,
  "name": "Vivian",
  "bookid21": 0,
  "type": 3,
  "bookid1": 19,
  "bookid18": 0,
  "bookid24": 0,
  "bookid19": 0,
  "bookid20": 0,
  "bookid4": 33,
  "bookid23": 0,
  "bookid11": 0,
  "sortid": 30,
  "bookid28": 0,
  "bookid29": 0,
  "bookid13": 0,
  "des": "{\"age\":\"23\",\"height\":\"162cm\",\"weight\":\"43kg\",\"constellation\":\"Pisces\",\"identity\":\"Makeup Artist\",\"des\":\"Young and promising makeup artist, although still young and inexperienced, she is skilled and serious about her work.\"}",
  "bookid10": 0,
  "bookid22": 0,
  "bookid15": 0,
  "getway": "Upgrade to \"C - List\" unlock",
  "label": "[6,14,18,24]",
  "bookid17": 0,
  "bookid14": 0,
  "bookid26": 0,
  "secrid": 200115,
  "bookid27": 0,
  "bookid12": 0,
  "bookid6": 0,
  "bookid16": 0,
  "bookid30": 0,
  "bookid7": 0,
  "bookid8": 0,
  "bookid2": 27,
  "spec": "[3]",
  "bookid9": 0,
  "bookid3": 32,
  "bookid5": 42,
  "bookid0": 3
}, {
  "bookid25": 0,
  "name": "Dany",
  "bookid21": 0,
  "type": 2,
  "bookid1": 14,
  "bookid18": 0,
  "bookid24": 0,
  "bookid19": 0,
  "bookid20": 0,
  "bookid4": 36,
  "bookid23": 0,
  "bookid11": 0,
  "sortid": 29,
  "bookid28": 0,
  "bookid29": 0,
  "bookid13": 0,
  "des": "{\"age\":\"28\",\"height\":\"168cm\",\"weight\":\"50kg\",\"constellation\":\"Gemini\",\"identity\":\"Editor\",\"des\":\"She has a kind personality and is capable of doing almost anything. Very detailed and enjoys socializing.\"}",
  "bookid10": 0,
  "bookid22": 0,
  "bookid15": 0,
  "getway": "Upgrade to \"D -List\" to unlock",
  "label": "[7,9,17,29]",
  "bookid17": 0,
  "bookid14": 0,
  "bookid26": 0,
  "secrid": 200114,
  "bookid27": 0,
  "bookid12": 0,
  "bookid6": 0,
  "bookid16": 0,
  "bookid30": 0,
  "bookid7": 0,
  "bookid8": 0,
  "bookid2": 16,
  "spec": "[2]",
  "bookid9": 0,
  "bookid3": 25,
  "bookid5": 42,
  "bookid0": 2
}, {
  "bookid25": 0,
  "name": "Cameraman Dax",
  "bookid21": 0,
  "type": 1,
  "bookid1": 5,
  "bookid18": 0,
  "bookid24": 0,
  "bookid19": 0,
  "bookid20": 0,
  "bookid4": 27,
  "bookid23": 0,
  "bookid11": 0,
  "sortid": 28,
  "bookid28": 0,
  "bookid29": 0,
  "bookid13": 0,
  "des": "{\"age\":\"34\",\"height\":\"182cm\",\"weight\":\"70kg\",\"constellation\":\"Cancer\",\"identity\":\"Videographer\",\"des\":\"He has an outstanding temperament and likes wine with work, enjoys jokind and always makes people around him happy.\"}",
  "bookid10": 0,
  "bookid22": 0,
  "bookid15": 0,
  "getway": "Upgrade to \"E -List\" to unlock",
  "label": "[11,14,17,23]",
  "bookid17": 0,
  "bookid14": 0,
  "bookid26": 0,
  "secrid": 200113,
  "bookid27": 0,
  "bookid12": 0,
  "bookid6": 0,
  "bookid16": 0,
  "bookid30": 0,
  "bookid7": 0,
  "bookid8": 0,
  "bookid2": 7,
  "spec": "[1]",
  "bookid9": 0,
  "bookid3": 18,
  "bookid5": 46,
  "bookid0": 1
}, {
  "bookid25": 0,
  "name": "Captain Mike",
  "bookid21": 0,
  "type": 2,
  "bookid1": 18,
  "bookid18": 0,
  "bookid24": 0,
  "bookid19": 0,
  "bookid20": 0,
  "bookid4": 42,
  "bookid23": 0,
  "bookid11": 0,
  "sortid": 27,
  "bookid28": 0,
  "bookid29": 0,
  "bookid13": 0,
  "des": "{\"age\":\"52\",\"height\":\"182cm\",\"weight\":\"70kg\",\"constellation\":\"Libra\",\"identity\":\"Captain\",\"des\":\"Captain of an elegant cruise. He is kind and generous, with wisdom he has accumulated over the years.\"}",
  "bookid10": 0,
  "bookid22": 0,
  "bookid15": 0,
  "getway": "Upgrade to \"F - List\" unlock",
  "label": "[7,13,29,30]",
  "bookid17": 0,
  "bookid14": 0,
  "bookid26": 0,
  "secrid": 200112,
  "bookid27": 0,
  "bookid12": 0,
  "bookid6": 0,
  "bookid16": 0,
  "bookid30": 0,
  "bookid7": 0,
  "bookid8": 0,
  "bookid2": 20,
  "spec": "[2]",
  "bookid9": 0,
  "bookid3": 29,
  "bookid5": 0,
  "bookid0": 1
}, {
  "bookid25": 0,
  "name": "Ueno Sho",
  "bookid21": 0,
  "type": 4,
  "bookid1": 16,
  "bookid18": 0,
  "bookid24": 0,
  "bookid19": 0,
  "bookid20": 0,
  "bookid4": 46,
  "bookid23": 0,
  "bookid11": 0,
  "sortid": 26,
  "bookid28": 0,
  "bookid29": 0,
  "bookid13": 0,
  "des": "{\"age\":\"34\",\"height\":\"174cm\",\"weight\":\"52kg\",\"constellation\":\"Capricorn\",\"identity\":\"President\",\"des\":\"The president of a large gaming company. Keeps a low profile and can always find details that have been overlooked.\"}",
  "bookid10": 0,
  "bookid22": 0,
  "bookid15": 0,
  "getway": "Upgrade to \"G - List\" to unlock",
  "label": "[9,12,16,34]",
  "bookid17": 0,
  "bookid14": 0,
  "bookid26": 0,
  "secrid": 200111,
  "bookid27": 0,
  "bookid12": 0,
  "bookid6": 0,
  "bookid16": 0,
  "bookid30": 0,
  "bookid7": 0,
  "bookid8": 0,
  "bookid2": 29,
  "spec": "[4]",
  "bookid9": 0,
  "bookid3": 44,
  "bookid5": 0,
  "bookid0": 1
}, {
  "bookid25": 0,
  "name": "Michelle",
  "bookid21": 0,
  "type": 1,
  "bookid1": 7,
  "bookid18": 0,
  "bookid24": 0,
  "bookid19": 0,
  "bookid20": 0,
  "bookid4": 40,
  "bookid23": 0,
  "bookid11": 0,
  "sortid": 25,
  "bookid28": 0,
  "bookid29": 0,
  "bookid13": 0,
  "des": "{\"age\":\"26\",\"height\":\"170cm\",\"weight\":\"49kg\",\"constellation\":\"Sagittarius\",\"identity\":\"Star\",\"des\":\"Bright and sexy, a wonderful singer, although a little difficult to get along with.\"}",
  "bookid10": 0,
  "bookid22": 0,
  "bookid15": 0,
  "getway": "Upgrade to \"H - List\" to unlock",
  "label": "[3,6,20,23]",
  "bookid17": 0,
  "bookid14": 0,
  "bookid26": 0,
  "secrid": 200110,
  "bookid27": 0,
  "bookid12": 0,
  "bookid6": 0,
  "bookid16": 0,
  "bookid30": 0,
  "bookid7": 0,
  "bookid8": 0,
  "bookid2": 16,
  "spec": "[1]",
  "bookid9": 0,
  "bookid3": 29,
  "bookid5": 0,
  "bookid0": 5
}, {
  "bookid25": 0,
  "name": "Willow",
  "bookid21": 0,
  "type": 3,
  "bookid1": 16,
  "bookid18": 0,
  "bookid24": 0,
  "bookid19": 0,
  "bookid20": 0,
  "bookid4": 44,
  "bookid23": 0,
  "bookid11": 0,
  "sortid": 24,
  "bookid28": 0,
  "bookid29": 0,
  "bookid13": 0,
  "des": "{\"age\":\"20\",\"height\":\"163cm\",\"weight\":\"43kg\",\"constellation\":\"Gemini\",\"identity\":\"Singer\",\"des\":\"Newbie eager for opportunities, has been working hard for any chance.\"}",
  "bookid10": 0,
  "bookid22": 0,
  "bookid15": 0,
  "getway": "Upgrade to \"I - Lines\" to unlock",
  "label": "[3,22,28,33]",
  "bookid17": 0,
  "bookid14": 0,
  "bookid26": 0,
  "secrid": 200109,
  "bookid27": 0,
  "bookid12": 0,
  "bookid6": 0,
  "bookid16": 0,
  "bookid30": 0,
  "bookid7": 0,
  "bookid8": 0,
  "bookid2": 31,
  "spec": "[3]",
  "bookid9": 0,
  "bookid3": 33,
  "bookid5": 0,
  "bookid0": 1
}, {
  "bookid25": 0,
  "name": "Ezra",
  "bookid21": 0,
  "type": 3,
  "bookid1": 18,
  "bookid18": 0,
  "bookid24": 0,
  "bookid19": 0,
  "bookid20": 0,
  "bookid4": 41,
  "bookid23": 0,
  "bookid11": 0,
  "sortid": 23,
  "bookid28": 0,
  "bookid29": 0,
  "bookid13": 0,
  "des": "{\"age\":\"23\",\"height\":\"181cm\",\"weight\":\"63kg\",\"constellation\":\"Sagittarius\",\"identity\":\"Star\",\"des\":\"Loves to flirt with the ladies, never denies his own moral flaws, but he has his principles in life.\"}",
  "bookid10": 0,
  "bookid22": 0,
  "bookid15": 0,
  "getway": "Upgrade to \"J -List\" to unlock",
  "label": "[4,6,26,32]",
  "bookid17": 0,
  "bookid14": 0,
  "bookid26": 0,
  "secrid": 200108,
  "bookid27": 0,
  "bookid12": 0,
  "bookid6": 0,
  "bookid16": 0,
  "bookid30": 0,
  "bookid7": 0,
  "bookid8": 0,
  "bookid2": 29,
  "spec": "[3]",
  "bookid9": 0,
  "bookid3": 33,
  "bookid5": 0,
  "bookid0": 1
}, {
  "bookid25": 0,
  "name": "Jupiter",
  "bookid21": 0,
  "type": 4,
  "bookid1": 15,
  "bookid18": 0,
  "bookid24": 0,
  "bookid19": 0,
  "bookid20": 0,
  "bookid4": 46,
  "bookid23": 0,
  "bookid11": 0,
  "sortid": 22,
  "bookid28": 0,
  "bookid29": 0,
  "bookid13": 0,
  "des": "{\"age\":\"34\",\"height\":\"178cm\",\"weight\":\"62kg\",\"constellation\":\"Scorpio\",\"identity\":\"Photographer\",\"des\":\"A reporter that catches it all. Although he has been criticized, his credibility is still the best.\"}",
  "bookid10": 0,
  "bookid22": 0,
  "bookid15": 0,
  "getway": "Upgrade to \"K - List\" to unlock",
  "label": "[8,13,18,33]",
  "bookid17": 0,
  "bookid14": 0,
  "bookid26": 0,
  "secrid": 200107,
  "bookid27": 0,
  "bookid12": 0,
  "bookid6": 0,
  "bookid16": 0,
  "bookid30": 0,
  "bookid7": 0,
  "bookid8": 0,
  "bookid2": 27,
  "spec": "[4]",
  "bookid9": 0,
  "bookid3": 42,
  "bookid5": 0,
  "bookid0": 5
}, {
  "bookid25": 0,
  "name": "Director Li",
  "bookid21": 0,
  "type": 1,
  "bookid1": 7,
  "bookid18": 0,
  "bookid24": 0,
  "bookid19": 0,
  "bookid20": 0,
  "bookid4": 44,
  "bookid23": 0,
  "bookid11": 0,
  "sortid": 21,
  "bookid28": 0,
  "bookid29": 0,
  "bookid13": 0,
  "des": "{\"age\":\"46\",\"height\":\"178cm\",\"weight\":\"65kg\",\"constellation\":\"Capricorn\",\"identity\":\"Director\",\"des\":\"Real deal director, getting a script from him is a sure ticket to fame.\"}",
  "bookid10": 0,
  "bookid22": 0,
  "bookid15": 0,
  "getway": "Upgrade to \"L -List\" to unlock",
  "label": "[14,17,27,28]",
  "bookid17": 0,
  "bookid14": 0,
  "bookid26": 0,
  "secrid": 200106,
  "bookid27": 0,
  "bookid12": 0,
  "bookid6": 0,
  "bookid16": 0,
  "bookid30": 0,
  "bookid7": 0,
  "bookid8": 0,
  "bookid2": 15,
  "spec": "[1]",
  "bookid9": 0,
  "bookid3": 27,
  "bookid5": 0,
  "bookid0": 3
}, {
  "bookid25": 0,
  "name": "Mr. O'Conner ",
  "bookid21": 0,
  "type": 2,
  "bookid1": 16,
  "bookid18": 0,
  "bookid24": 0,
  "bookid19": 0,
  "bookid20": 0,
  "bookid4": 41,
  "bookid23": 0,
  "bookid11": 0,
  "sortid": 20,
  "bookid28": 0,
  "bookid29": 0,
  "bookid13": 0,
  "des": "{\"age\":\"34\",\"height\":\"176cm\",\"weight\":\"65kg\",\"constellation\":\"Aquarius\",\"identity\":\"Young Director\",\"des\":\"Natural producer, specializing in the film and television industry. He may still be young, but there is no denying his talent.\"}",
  "bookid10": 0,
  "bookid22": 0,
  "bookid15": 0,
  "getway": "Upgrade to \"M - List\" to unlock",
  "label": "[8,16,24,31]",
  "bookid17": 0,
  "bookid14": 0,
  "bookid26": 0,
  "secrid": 200105,
  "bookid27": 0,
  "bookid12": 0,
  "bookid6": 0,
  "bookid16": 0,
  "bookid30": 0,
  "bookid7": 0,
  "bookid8": 0,
  "bookid2": 20,
  "spec": "[2]",
  "bookid9": 0,
  "bookid3": 31,
  "bookid5": 0,
  "bookid0": 1
}, {
  "bookid25": 0,
  "name": "Boyan Chen",
  "bookid21": 0,
  "type": 3,
  "bookid1": 15,
  "bookid18": 0,
  "bookid24": 0,
  "bookid19": 0,
  "bookid20": 0,
  "bookid4": 40,
  "bookid23": 0,
  "bookid11": 0,
  "sortid": 19,
  "bookid28": 0,
  "bookid29": 0,
  "bookid13": 0,
  "des": "{\"age\":\"25\",\"height\":\"180cm\",\"weight\":\"59kg\",\"constellation\":\"Virgo\",\"identity\":\"Actor\",\"des\":\"A mature male actor unwilling to flocker, prefers to spend time reading classical books.\"}",
  "bookid10": 0,
  "bookid22": 0,
  "bookid15": 0,
  "getway": "Upgrade to \"N - List\" to unlock",
  "label": "[4,21,27,33]",
  "bookid17": 0,
  "bookid14": 0,
  "bookid26": 0,
  "secrid": 200104,
  "bookid27": 0,
  "bookid12": 0,
  "bookid6": 0,
  "bookid16": 0,
  "bookid30": 0,
  "bookid7": 0,
  "bookid8": 0,
  "bookid2": 29,
  "spec": "[3]",
  "bookid9": 0,
  "bookid3": 31,
  "bookid5": 0,
  "bookid0": 2
}, {
  "bookid25": 0,
  "name": "Mr. Fred",
  "bookid21": 0,
  "type": 4,
  "bookid1": 15,
  "bookid18": 0,
  "bookid24": 0,
  "bookid19": 0,
  "bookid20": 0,
  "bookid4": 50,
  "bookid23": 0,
  "bookid11": 0,
  "sortid": 18,
  "bookid28": 0,
  "bookid29": 0,
  "bookid13": 0,
  "des": "{\"age\":\"53\",\"height\":\"172cm\",\"weight\":\"74kg\",\"constellation\":\"Cancer\",\"identity\":\"Emperor Security\",\"des\":\"A retired veteran, knows a thing or two about self-defense although he is kind and generous most of the time.\"}",
  "bookid10": 0,
  "bookid22": 0,
  "bookid15": 0,
  "getway": "Upgrade to \"O - List\" to unlock",
  "label": "[11,20,22]",
  "bookid17": 0,
  "bookid14": 0,
  "bookid26": 0,
  "secrid": 200103,
  "bookid27": 0,
  "bookid12": 0,
  "bookid6": 0,
  "bookid16": 0,
  "bookid30": 0,
  "bookid7": 0,
  "bookid8": 0,
  "bookid2": 28,
  "spec": "[4]",
  "bookid9": 0,
  "bookid3": 40,
  "bookid5": 0,
  "bookid0": 1
}, {
  "bookid25": 0,
  "name": "Brody",
  "bookid21": 0,
  "type": 2,
  "bookid1": 16,
  "bookid18": 0,
  "bookid24": 0,
  "bookid19": 0,
  "bookid20": 0,
  "bookid4": 41,
  "bookid23": 0,
  "bookid11": 0,
  "sortid": 17,
  "bookid28": 0,
  "bookid29": 0,
  "bookid13": 0,
  "des": "{\"age\":\"29\",\"height\":\"180cm\",\"weight\":\"59kg\",\"constellation\":\"Virgo\",\"identity\":\"Host\",\"des\":\"An easy-going host with a humorous spark, making the entire audience look forward to what he has to say next.\"}",
  "bookid10": 0,
  "bookid22": 0,
  "bookid15": 0,
  "getway": "Upgrade to \"P - List\" to unlock",
  "label": "[7,24,25,34]",
  "bookid17": 0,
  "bookid14": 0,
  "bookid26": 0,
  "secrid": 200102,
  "bookid27": 0,
  "bookid12": 0,
  "bookid6": 0,
  "bookid16": 0,
  "bookid30": 0,
  "bookid7": 0,
  "bookid8": 0,
  "bookid2": 18,
  "spec": "[2]",
  "bookid9": 0,
  "bookid3": 28,
  "bookid5": 0,
  "bookid0": 1
}, {
  "bookid25": 0,
  "name": "Sophia Chen",
  "bookid21": 0,
  "type": 1,
  "bookid1": 8,
  "bookid18": 0,
  "bookid24": 0,
  "bookid19": 0,
  "bookid20": 0,
  "bookid4": 41,
  "bookid23": 0,
  "bookid11": 0,
  "sortid": 16,
  "bookid28": 0,
  "bookid29": 0,
  "bookid13": 0,
  "des": "{\"age\":\"18\",\"height\":\"164cm\",\"weight\":\"45kg\",\"constellation\":\"Cancer\",\"identity\":\"Student\",\"des\":\"Your super supportive fan, has a certain appeal and influence in the fan circle.\"}",
  "bookid10": 0,
  "bookid22": 0,
  "bookid15": 0,
  "getway": "Upgrade to \"Q - List\" to unlock",
  "label": "[8,18,26]",
  "bookid17": 0,
  "bookid14": 0,
  "bookid26": 0,
  "secrid": 200101,
  "bookid27": 0,
  "bookid12": 0,
  "bookid6": 0,
  "bookid16": 0,
  "bookid30": 0,
  "bookid7": 0,
  "bookid8": 0,
  "bookid2": 15,
  "spec": "[1]",
  "bookid9": 0,
  "bookid3": 27,
  "bookid5": 0,
  "bookid0": 2
}, {
  "bookid25": 0,
  "name": "Dorris",
  "bookid21": 0,
  "type": 1,
  "bookid1": 7,
  "bookid18": 0,
  "bookid24": 0,
  "bookid19": 0,
  "bookid20": 0,
  "bookid4": 40,
  "bookid23": 0,
  "bookid11": 0,
  "sortid": 15,
  "bookid28": 0,
  "bookid29": 0,
  "bookid13": 0,
  "des": "{\"age\":\"28\",\"height\":\"172cm\",\"weight\":\"45kg\",\"constellation\":\"Sagittarius\",\"identity\":\"A-List Star\",\"des\":\"A one of a kind celebrity star, acting out her heart on the set. She looks glamorous, but her heart is trapped in love...\"}",
  "bookid10": 0,
  "bookid22": 0,
  "bookid15": 0,
  "getway": "Complete levels 9-10 to unlock",
  "label": "[5,6,21]",
  "bookid17": 0,
  "bookid14": 0,
  "bookid26": 0,
  "secrid": 200015,
  "bookid27": 0,
  "bookid12": 0,
  "bookid6": 0,
  "bookid16": 0,
  "bookid30": 0,
  "bookid7": 0,
  "bookid8": 0,
  "bookid2": 16,
  "spec": "[1]",
  "bookid9": 0,
  "bookid3": 29,
  "bookid5": 0,
  "bookid0": 5
}, {
  "bookid25": 0,
  "name": "Eleanor",
  "bookid21": 0,
  "type": 3,
  "bookid1": 15,
  "bookid18": 0,
  "bookid24": 0,
  "bookid19": 0,
  "bookid20": 0,
  "bookid4": 42,
  "bookid23": 0,
  "bookid11": 0,
  "sortid": 14,
  "bookid28": 0,
  "bookid29": 0,
  "bookid13": 0,
  "des": "{\"age\":\"21\",\"height\":\"168cm\",\"weight\":\"40kg\",\"constellation\":\"Leo\",\"identity\":\"Upcoming Actress\",\"des\":\"A little arrogant, but hardworking and vigilent, looking for her breakthrough.\"}",
  "bookid10": 0,
  "bookid22": 0,
  "bookid15": 0,
  "getway": "Complete levels 8-10 to unlock",
  "label": "[19,27,33]",
  "bookid17": 0,
  "bookid14": 0,
  "bookid26": 0,
  "secrid": 200014,
  "bookid27": 0,
  "bookid12": 0,
  "bookid6": 0,
  "bookid16": 0,
  "bookid30": 0,
  "bookid7": 0,
  "bookid8": 0,
  "bookid2": 29,
  "spec": "[3]",
  "bookid9": 0,
  "bookid3": 33,
  "bookid5": 0,
  "bookid0": 1
}, {
  "bookid25": 0,
  "name": "Scarlett",
  "bookid21": 0,
  "type": 1,
  "bookid1": 7,
  "bookid18": 0,
  "bookid24": 0,
  "bookid19": 0,
  "bookid20": 0,
  "bookid4": 42,
  "bookid23": 0,
  "bookid11": 0,
  "sortid": 13,
  "bookid28": 0,
  "bookid29": 0,
  "bookid13": 0,
  "des": "{\"age\":\"22\",\"height\":\"166cm\",\"weight\":\"42kg\",\"constellation\":\"Sagittarius\",\"identity\":\"Star\",\"des\":\"Proud newbie with a high self-esteem and high goals. She will do anything to get what she wants.\"}",
  "bookid10": 0,
  "bookid22": 0,
  "bookid15": 0,
  "getway": "Complete levels 7-10 to unlock",
  "label": "[2,6,24,25]",
  "bookid17": 0,
  "bookid14": 0,
  "bookid26": 0,
  "secrid": 200013,
  "bookid27": 0,
  "bookid12": 0,
  "bookid6": 0,
  "bookid16": 0,
  "bookid30": 0,
  "bookid7": 0,
  "bookid8": 0,
  "bookid2": 15,
  "spec": "[1]",
  "bookid9": 0,
  "bookid3": 27,
  "bookid5": 0,
  "bookid0": 3
}, {
  "bookid25": 0,
  "name": "Salsa",
  "bookid21": 0,
  "type": 4,
  "bookid1": 19,
  "bookid18": 0,
  "bookid24": 0,
  "bookid19": 0,
  "bookid20": 0,
  "bookid4": 46,
  "bookid23": 0,
  "bookid11": 0,
  "sortid": 12,
  "bookid28": 0,
  "bookid29": 0,
  "bookid13": 0,
  "des": "{\"age\":\"22\",\"height\":\"165cm\",\"weight\":\"42kg\",\"constellation\":\"Gemini\",\"identity\":\"Waitress\",\"des\":\"Serving at the hotel restaurant, appears to be the ordinary girl, but it is actually an expert at inquiring about all kinds of gossip.\"}",
  "bookid10": 0,
  "bookid22": 0,
  "bookid15": 0,
  "getway": "Complete levels 6-10 to unlock",
  "label": "[15,20,30,32]",
  "bookid17": 0,
  "bookid14": 0,
  "bookid26": 0,
  "secrid": 200012,
  "bookid27": 0,
  "bookid12": 0,
  "bookid6": 0,
  "bookid16": 0,
  "bookid30": 0,
  "bookid7": 0,
  "bookid8": 0,
  "bookid2": 28,
  "spec": "[4]",
  "bookid9": 0,
  "bookid3": 42,
  "bookid5": 0,
  "bookid0": 1
}, {
  "bookid25": 0,
  "name": "Lucy",
  "bookid21": 0,
  "type": 3,
  "bookid1": 14,
  "bookid18": 0,
  "bookid24": 0,
  "bookid19": 0,
  "bookid20": 0,
  "bookid4": 41,
  "bookid23": 0,
  "bookid11": 0,
  "sortid": 11,
  "bookid28": 0,
  "bookid29": 0,
  "bookid13": 0,
  "des": "{\"age\":\"23\",\"height\":\"167cm\",\"weight\":\"45kg\",\"constellation\":\"Taurus\",\"identity\":\"Newbie Actress\",\"des\":\"A quiet girl at heart, not interested in the chaos of the entertainment industry.\"}",
  "bookid10": 0,
  "bookid22": 0,
  "bookid15": 0,
  "getway": "Complete levels 5-10 to unlock",
  "label": "[4,5,19,23]",
  "bookid17": 0,
  "bookid14": 0,
  "bookid26": 0,
  "secrid": 200011,
  "bookid27": 0,
  "bookid12": 0,
  "bookid6": 0,
  "bookid16": 0,
  "bookid30": 0,
  "bookid7": 0,
  "bookid8": 0,
  "bookid2": 28,
  "spec": "[3,1]",
  "bookid9": 0,
  "bookid3": 29,
  "bookid5": 0,
  "bookid0": 7
}, {
  "bookid25": 0,
  "name": "J-Kopper",
  "bookid21": 0,
  "type": 2,
  "bookid1": 14,
  "bookid18": 0,
  "bookid24": 0,
  "bookid19": 0,
  "bookid20": 0,
  "bookid4": 40,
  "bookid23": 0,
  "bookid11": 0,
  "sortid": 10,
  "bookid28": 0,
  "bookid29": 0,
  "bookid13": 0,
  "des": "{\"age\":\"23\",\"height\":\"175cm\",\"weight\":\"52kg\",\"constellation\":\"Libra\",\"identity\":\"Singer\",\"des\":\"New voice just beginning to expand his musical career. Dispite his fierce rocker look, he actually has sweet romantic vocals.\"}",
  "bookid10": 0,
  "bookid22": 0,
  "bookid15": 0,
  "getway": "Complete levels 4-10 to unlock",
  "label": "[1,3,5,21]",
  "bookid17": 0,
  "bookid14": 0,
  "bookid26": 0,
  "secrid": 200010,
  "bookid27": 0,
  "bookid12": 0,
  "bookid6": 0,
  "bookid16": 0,
  "bookid30": 0,
  "bookid7": 0,
  "bookid8": 0,
  "bookid2": 18,
  "spec": "[2,3]",
  "bookid9": 0,
  "bookid3": 33,
  "bookid5": 0,
  "bookid0": 2
}, {
  "bookid25": 0,
  "name": "David",
  "bookid21": 0,
  "type": 2,
  "bookid1": 20,
  "bookid18": 0,
  "bookid24": 0,
  "bookid19": 0,
  "bookid20": 0,
  "bookid4": 44,
  "bookid23": 0,
  "bookid11": 0,
  "sortid": 9,
  "bookid28": 0,
  "bookid29": 0,
  "bookid13": 0,
  "des": "{\"age\":\"37\",\"height\":\"177cm\",\"weight\":\"68kg\",\"constellation\":\"Aquarius\",\"identity\":\"agent\",\"des\":\"A skilled agent, regards artists as just products to buy and sell. Focuses on short-term profits, but always accurately grasps the market trend.\"}",
  "bookid10": 0,
  "bookid22": 0,
  "bookid15": 0,
  "getway": "Complete levels 3-10 to unlock",
  "label": "[10,20,31]",
  "bookid17": 0,
  "bookid14": 0,
  "bookid26": 0,
  "secrid": 200009,
  "bookid27": 0,
  "bookid12": 0,
  "bookid6": 0,
  "bookid16": 0,
  "bookid30": 0,
  "bookid7": 0,
  "bookid8": 0,
  "bookid2": 28,
  "spec": "[2,4]",
  "bookid9": 0,
  "bookid3": 40,
  "bookid5": 0,
  "bookid0": 1
}, {
  "bookid25": 0,
  "name": "Kerry",
  "bookid21": 0,
  "type": 2,
  "bookid1": 15,
  "bookid18": 0,
  "bookid24": 0,
  "bookid19": 0,
  "bookid20": 0,
  "bookid4": 41,
  "bookid23": 0,
  "bookid11": 0,
  "sortid": 8,
  "bookid28": 0,
  "bookid29": 0,
  "bookid13": 0,
  "des": "{\"age\":\"30\",\"height\":\"160cm\",\"weight\":\"52kg\",\"constellation\":\"Aquarius\",\"identity\":\"Screenwriter\",\"des\":\"An old friend from your past life. A struggling screenwriter that is a fan of Japanese anime.\"}",
  "bookid10": 0,
  "bookid22": 0,
  "bookid15": 0,
  "getway": "Complete levels 3-5 to unlock",
  "label": "[17,22,23]",
  "bookid17": 0,
  "bookid14": 0,
  "bookid26": 0,
  "secrid": 200008,
  "bookid27": 0,
  "bookid12": 0,
  "bookid6": 0,
  "bookid16": 0,
  "bookid30": 0,
  "bookid7": 0,
  "bookid8": 0,
  "bookid2": 16,
  "spec": "[2,1]",
  "bookid9": 0,
  "bookid3": 27,
  "bookid5": 0,
  "bookid0": 7
}, {
  "bookid25": 0,
  "name": "Secretary Kim",
  "bookid21": 0,
  "type": 3,
  "bookid1": 15,
  "bookid18": 0,
  "bookid24": 0,
  "bookid19": 0,
  "bookid20": 0,
  "bookid4": 46,
  "bookid23": 0,
  "bookid11": 0,
  "sortid": 7,
  "bookid28": 0,
  "bookid29": 0,
  "bookid13": 0,
  "des": "{\"age\":\"27\",\"height\":\"179cm\",\"weight\":\"73kg\",\"constellation\":\"Aries\",\"identity\":\"Assistant\",\"des\":\"A shrewd and capable industry leading assistant. He knows what needs to be done and how to do it.\"}",
  "bookid10": 0,
  "bookid22": 0,
  "bookid15": 0,
  "getway": "Complete level 3-3 to unlock",
  "label": "[12,21,31]",
  "bookid17": 0,
  "bookid14": 0,
  "bookid26": 0,
  "secrid": 200007,
  "bookid27": 0,
  "bookid12": 0,
  "bookid6": 0,
  "bookid16": 0,
  "bookid30": 0,
  "bookid7": 0,
  "bookid8": 0,
  "bookid2": 27,
  "spec": "[3,4]",
  "bookid9": 0,
  "bookid3": 31,
  "bookid5": 0,
  "bookid0": 1
}, {
  "bookid25": 0,
  "name": "Konishi",
  "bookid21": 0,
  "type": 4,
  "bookid1": 15,
  "bookid18": 0,
  "bookid24": 0,
  "bookid19": 0,
  "bookid20": 0,
  "bookid4": 42,
  "bookid23": 0,
  "bookid11": 0,
  "sortid": 6,
  "bookid28": 0,
  "bookid29": 0,
  "bookid13": 0,
  "des": "{\"age\":\"22\",\"height\":\"176cm\",\"weight\":\"54kg\",\"constellation\":\"Libra\",\"identity\":\"Assistant\",\"des\":\"Crazy about music, everything he says and does circles around his passion for it.\"}",
  "bookid10": 0,
  "bookid22": 0,
  "bookid15": 0,
  "getway": "Complete levels 2-10 to unlock",
  "label": "[1,25,29]",
  "bookid17": 0,
  "bookid14": 0,
  "bookid26": 0,
  "secrid": 200006,
  "bookid27": 0,
  "bookid12": 0,
  "bookid6": 0,
  "bookid16": 0,
  "bookid30": 0,
  "bookid7": 0,
  "bookid8": 0,
  "bookid2": 27,
  "spec": "[4,1]",
  "bookid9": 0,
  "bookid3": 41,
  "bookid5": 0,
  "bookid0": 7
}, {
  "bookid25": 0,
  "name": "Lee",
  "bookid21": 0,
  "type": 3,
  "bookid1": 15,
  "bookid18": 0,
  "bookid24": 0,
  "bookid19": 0,
  "bookid20": 0,
  "bookid4": 40,
  "bookid23": 0,
  "bookid11": 0,
  "sortid": 5,
  "bookid28": 0,
  "bookid29": 0,
  "bookid13": 0,
  "des": "{\"age\":\"22\",\"height\":\"168cm\",\"weight\":\"48kg\",\"constellation\":\"Gemini\",\"identity\":\"Star\",\"des\":\"Beautiful and trendy, but can also be fierce when she sees something being done wrong.\"}",
  "bookid10": 0,
  "bookid22": 0,
  "bookid15": 0,
  "getway": "Complete level 2-2 to unlock",
  "label": "[2,3,32]",
  "bookid17": 0,
  "bookid14": 0,
  "bookid26": 0,
  "secrid": 200005,
  "bookid27": 0,
  "bookid12": 0,
  "bookid6": 0,
  "bookid16": 0,
  "bookid30": 0,
  "bookid7": 0,
  "bookid8": 0,
  "bookid2": 28,
  "spec": "[3]",
  "bookid9": 0,
  "bookid3": 31,
  "bookid5": 0,
  "bookid0": 1
}, {
  "bookid25": 0,
  "name": "Carla",
  "bookid21": 0,
  "type": 1,
  "bookid1": 5,
  "bookid18": 0,
  "bookid24": 0,
  "bookid19": 0,
  "bookid20": 0,
  "bookid4": 41,
  "bookid23": 0,
  "bookid11": 0,
  "sortid": 4,
  "bookid28": 0,
  "bookid29": 0,
  "bookid13": 0,
  "des": "{\"age\":\"26\",\"height\":\"168cm\",\"weight\":\"49kg\",\"constellation\":\"Virgo\",\"identity\":\"Agent\",\"des\":\"Once a variety show director. Now an entertainment company agent, very professional and capable.\"}",
  "bookid10": 0,
  "bookid22": 0,
  "bookid15": 0,
  "getway": "Complete levels 1-10 to unlock",
  "label": "[10,34]",
  "bookid17": 0,
  "bookid14": 0,
  "bookid26": 0,
  "secrid": 200004,
  "bookid27": 0,
  "bookid12": 0,
  "bookid6": 0,
  "bookid16": 0,
  "bookid30": 0,
  "bookid7": 0,
  "bookid8": 0,
  "bookid2": 14,
  "spec": "[1]",
  "bookid9": 0,
  "bookid3": 27,
  "bookid5": 0,
  "bookid0": 2
}, {
  "bookid25": 0,
  "name": "Nurse Lin",
  "bookid21": 0,
  "type": 2,
  "bookid1": 20,
  "bookid18": 0,
  "bookid24": 0,
  "bookid19": 0,
  "bookid20": 0,
  "bookid4": 0,
  "bookid23": 0,
  "bookid11": 0,
  "sortid": 3,
  "bookid28": 0,
  "bookid29": 0,
  "bookid13": 0,
  "des": "{\"age\":\"21\",\"height\":\"160cm\",\"weight\":\"47kg\",\"constellation\":\"Cancer\",\"identity\":\"Nurse\",\"des\":\"Kind and thoughtful young nurse, always thinking about how to make you feel better.\"}",
  "bookid10": 0,
  "bookid22": 0,
  "bookid15": 0,
  "getway": "Complete levels 1-5 to unlock",
  "label": "[26,29]",
  "bookid17": 0,
  "bookid14": 0,
  "bookid26": 0,
  "secrid": 200003,
  "bookid27": 0,
  "bookid12": 0,
  "bookid6": 0,
  "bookid16": 0,
  "bookid30": 0,
  "bookid7": 0,
  "bookid8": 0,
  "bookid2": 28,
  "spec": "[2]",
  "bookid9": 0,
  "bookid3": 41,
  "bookid5": 0,
  "bookid0": 1
}, {
  "bookid25": 0,
  "name": "Pam",
  "bookid21": 0,
  "type": 4,
  "bookid1": 15,
  "bookid18": 0,
  "bookid24": 0,
  "bookid19": 0,
  "bookid20": 0,
  "bookid4": 44,
  "bookid23": 0,
  "bookid11": 0,
  "sortid": 2,
  "bookid28": 0,
  "bookid29": 0,
  "bookid13": 0,
  "des": "{\"age\":\"21\",\"height\":\"163cm\",\"weight\":\"54kg\",\"constellation\":\"Libra\",\"identity\":\"Comedian\",\"des\":\"Trained with you to become a star. After failing to debut, she became a comedian instead. A serious foodie.\"}",
  "bookid10": 0,
  "bookid22": 0,
  "bookid15": 0,
  "getway": "Complete levels 1-2 to unlock",
  "label": "[18,28]",
  "bookid17": 0,
  "bookid14": 0,
  "bookid26": 0,
  "secrid": 200002,
  "bookid27": 0,
  "bookid12": 0,
  "bookid6": 0,
  "bookid16": 0,
  "bookid30": 0,
  "bookid7": 0,
  "bookid8": 0,
  "bookid2": 27,
  "spec": "[4]",
  "bookid9": 0,
  "bookid3": 41,
  "bookid5": 0,
  "bookid0": 1
}, {
  "bookid25": 0,
  "name": "Lily",
  "bookid21": 0,
  "type": 1,
  "bookid1": 15,
  "bookid18": 0,
  "bookid24": 0,
  "bookid19": 0,
  "bookid20": 0,
  "bookid4": 0,
  "bookid23": 0,
  "bookid11": 0,
  "sortid": 1,
  "bookid28": 0,
  "bookid29": 0,
  "bookid13": 0,
  "des": "{\"age\":\"19\",\"height\":\"158cm\",\"weight\":\"46kg\",\"constellation\":\"Taurus\",\"identity\":\"Assistant\",\"des\":\"Your caring assistant, your personal helper along the way.\"}",
  "bookid10": 0,
  "bookid22": 0,
  "bookid15": 0,
  "getway": "Complete levels 1-2 to unlock",
  "label": "[19,30]",
  "bookid17": 0,
  "bookid14": 0,
  "bookid26": 0,
  "secrid": 200001,
  "bookid27": 0,
  "bookid12": 0,
  "bookid6": 0,
  "bookid16": 0,
  "bookid30": 0,
  "bookid7": 0,
  "bookid8": 0,
  "bookid2": 28,
  "spec": "[1]",
  "bookid9": 0,
  "bookid3": 41,
  "bookid5": 0,
  "bookid0": 2
}]