<template lang='pug'>
  .partners
    .partners-list.fil-rounded-box(v-if="nextPartner")
      .title Next Partner
      .partner-container(v-if="partnerIds[nextPartner]")
        .counter(:style="{width: `${getTimeLeft(partnerIds[nextPartner].dispatchDate || 0)/28 * 100}%`}")
        .partner-row(@click="editAbility(nextPartner)")
          .partner-image
            img(:src="require(`@/assets/images/partners/${nextPartner}.png`)" :alt="partnerIds[nextPartner].name")
            .next
              span Next
          .partner-data
            .name {{partnerIds[nextPartner].name}}
            .details
              .info
                .time-left
                  .time {{getTimeLeft(partnerIds[nextPartner].dispatchDate || 0) > 0 ? `${getTimeLeft(partnerIds[nextPartner].dispatchDate || 0)} Day${getTimeLeft(partnerIds[nextPartner].dispatchDate || 0)  === 1 ? '' : 's'} to go` : `Available for dispatch`}}
                .overall-ability(v-if="partnerIds[nextPartner].ability") Ability: {{formatNumber(partnerIds[nextPartner].ability)}}
              .action
                button.dispatch(@click.stop="tryDispatch(nextPartner)" v-if="getTimeLeft(partnerIds[nextPartner].dispatchDate || 0) <= 0") Dispatch Now
                button.cancel(@click.stop="cancelNext()") Cancel

    .partners-list.fil-rounded-box(v-if="coolDownPartners && coolDownPartners.length > 0")
      .title Dispatched Partners
      .partner-container(v-for="(partner, i) in coolDownPartners" :key="`partner-${partner}`")
        .counter(:style="{width: `${getTimeLeft(partnerIds[partner].dispatchDate || 0)/28 * 100}%`}")
        .partner-row(@click="editAbility(partner)")
          .partner-image
            img(:src="require(`@/assets/images/partners/${partner}.png`)" :alt="partnerIds[partner].name")
            .next(v-if="partner == nextPartner")
                span Next
          .partner-data
            .name {{partnerIds[partner].name}}
            .details
              .info
                .time-left
                  .time {{getTimeLeft(partnerIds[partner].dispatchDate || 0)}} Day{{getTimeLeft(partnerIds[partner].dispatchDate || 0) === 1 ? '' : 's'}} to go
                .overall-ability(v-if="partnerIds[partner].ability") Ability: {{formatNumber(partnerIds[partner].ability)}}
              .action
                button.dispatch-next(@click.stop="tryDispatchNext(partner)") Dispatch Next
                button.cancel(@click.stop="cancelCooldown(partner)") Cancel

    .partners-list.fil-rounded-box
      .title Available Partners
      .partner-row(v-for="(partner, i) in sortedPartners" :key="`partner-${partner}`" v-if="partner && getTimeLeft(partnerIds[partner].dispatchDate || 0) <= 0" @click="editAbility(partner)")
        .partner-image
          img(:src="require(`@/assets/images/partners/${partner}.png`)" :alt="partnerIds[partner].name")
          .next(v-if="partner == nextPartner")
            span Next
        .partner-data
          .name {{partnerIds[partner].name}}
          .details
            .info
              .time-left
                .time Available for dispatch
              .overall-ability(v-if="partnerIds[partner].ability") Ability: {{formatNumber(partnerIds[partner].ability)}}
            .action
              button.dispatch-next(@click.stop="tryDispatchNext(partner)") Dispatch Next
              button.dispatch(@click.stop="tryDispatch(partner)") Dispatch
    .modal-container(v-if="isModalOpen")
      .overlay(@click.stop="closeModal()")
      .modal.dispatch-modal(v-if="isDispatching")
        .title-row
          .img(:style="{backgroundImage: `url(${require(`@/assets/images/partners/${dispatching}.png`)})`}" :alt="partnerIds[dispatching].name")
          .name {{partnerIds[dispatching].name}}
        .form
          .form-row
            label.dispatch-time(for="dispatch-time") Dispatch Date
            datepicker#dispatch-time(placeholder="Select Date" v-model="dispatchDate" input-class="dispatch-time-picker" :disabled-dates="disabledDates")
          .form-row
            label.ability(for="ability") Ability
            input#ability(type="number" v-model="dispatchingAbility")
        .action
          button.dispatch(@click.stop="dispatch(dispatching)") Dispatch

      .modal.dispatch-modal(v-if="isNextDispatch")
        .title-row
          .img(:style="{backgroundImage: `url(${require(`@/assets/images/partners/${dispatching}.png`)})`}" :alt="partnerIds[dispatching].name")
          .name {{partnerIds[dispatching].name}}
        .form-row
          label.ability(for="ability") Ability
          input#ability(type="number" v-model="dispatchingNextAbility")
        .action
          button.dispatch(@click.stop="dispatchNext(dispatching)" :disabled="!dispatchingNextAbility") Dispatch Next

      .modal.ability-modal(v-if="isAbilityChange")
        .title-row
          .img(:style="{backgroundImage: `url(${require(`@/assets/images/partners/${dispatching}.png`)})`}" :alt="partnerIds[dispatching].name")
          .name {{partnerIds[dispatching].name}}
        .form-row
          label.ability(for="ability") Ability
          input#ability(type="number" v-model="dispatchingNextAbility")
        .action
          button.dispatch(@click.stop="saveAbility(dispatching)" :disabled="!dispatchingNextAbility") Save

</template>

<script>
// @ is an alias to /src
import Partners from '@/data/partners.js'
import Datepicker from 'vuejs-datepicker'
import moment from 'moment'

export default {
  name: 'Partners',
  components: {
    Datepicker
  },
  data () {
    return {
      partnerIds: {},
      partners: [],
      dispatching: null,
      isModalOpen: false,
      isDispatching: false,
      isNextDispatch: false,
      isAbilityChange: false,
      dispatchDate: new Date(),
      disabledDates: {
        from: new Date()
      },
      dispatchingNextAbility: null,
      nextAbility: null,
      nextPartner: null,
      dispatchingAbility: null,
      serverPartners: null
    }
  },
  watch: {
    usernameDetails: function (val, oldVal) {
      if (val === oldVal) return
      this.dispatching = null
      this.isModalOpen = false
      this.isDispatching = false
      this.isNextDispatch = false
      this.dispatchDate = new Date()
      this.dispatchingNextAbility = null
      this.nextAbility = null
      this.nextPartner = null
      this.dispatchingAbility = null
      this.serverPartners = null
      if (!this.isLoggedIn) {
        this.partnerIds = {}
        this.partners = []
      } else {
        this.partnerIds = {}
        this.partners = []
        const partnerIds = {}
        Partners.forEach(partner => {
          partnerIds[partner.secrid] = { ...partner }
        })
        this.partnerIds = partnerIds
        this.partners = Object.keys(partnerIds).sort((a, b) => a > b ? 1 : -1)
        this.getPartners()
      }
    }
  },
  computed: {
    usernameDetails: function () {
      return `${this.$store.state.username}_${this.$store.state.serverNumber}_${this.$store.state.serverLocation}`
    },
    isLoggedIn: function () {
      return this.$store.state.isLoggedIn
    },
    sortedPartners: function () {
      if (!this.partners) return []
      const partners = [...this.partners]
      const sortedPartners = partners.sort((a, b) => {
        const timeLeftA = this.getTimeLeft(this.partnerIds[a].dispatchDate || 0)
        const timeLeftB = this.getTimeLeft(this.partnerIds[b].dispatchDate || 0)
        if (timeLeftA === timeLeftB) {
          console.log('sort')
          const abilityA = this.partnerIds[a].ability || 0
          const abilityB = this.partnerIds[b].ability || 0
          if (abilityA === abilityB) {
            return this.partnerIds[a].secrid > this.partnerIds[b].secrid ? 1 : -1
          } else {
            return abilityA < abilityB ? 1 : -1
          }
        } else return timeLeftA > timeLeftB ? 1 : -1
      })
      return sortedPartners
    },
    coolDownPartners: function () {
      if (!this.sortedPartners) return []
      const coolDownPartners = []
      this.sortedPartners.forEach(el => {
        if (this.getTimeLeft(this.partnerIds[el].dispatchDate || 0) > 0) coolDownPartners.push(el)
      })
      return coolDownPartners
    }
  },
  mounted () {
    const partnerIds = {}
    Partners.forEach(partner => {
      partnerIds[partner.secrid] = { ...partner }
    })
    this.partnerIds = partnerIds
    this.partners = Object.keys(partnerIds).sort((a, b) => a > b ? 1 : -1)
    this.getPartners()
  },
  methods: {
    formatNumber (value) {
      if ((('' + value).length - 1) < 6) return value
      if (value > 1000000000000) return '999B+'
      const suffixes = ['', '', 'M', 'B']
      const suffixNum = Math.floor((('' + value).length - 1) / 3)
      let shortValue = parseFloat((suffixNum !== 0 ? (value / Math.pow(1000, suffixNum)) : value).toPrecision(3))
      if (shortValue % 1 !== 0) {
        shortValue = shortValue.toFixed(2)
      }
      return shortValue + suffixes[suffixNum]
    },
    getPartners () {
      this.$http.get('/partners/', { headers: { Authorization: `Bearer ${localStorage.fil_jwt}` } })
        .then((response) => {
          if (!response.data || !response.data.partners) return
          const tempPartnerIds = { ...this.partnerIds }
          response.data.partners.forEach(p => {
            tempPartnerIds[p.partnerId].ability = p.overallAbility
            tempPartnerIds[p.partnerId].next = p.next
            tempPartnerIds[p.partnerId].dispatchDate = p.PartnerDispatches[0] && p.PartnerDispatches[0].dispatchTime
            if (p.next) {
              this.nextAbility = p.overallAbility
              this.nextPartner = p.partnerId
            }
          })
          this.partnerIds = tempPartnerIds
        }).catch((error) => {
          return error
        })
    },
    cancelCooldown (partnerId) {
      const partners = [...this.partners]
      this.$http.post('/partners/dispatch', {
        partnerId: partnerId
      }, { headers: { Authorization: `Bearer ${localStorage.fil_jwt}` } }).then((response) => {
        partners.forEach(el => {
          if (el.secrid !== partnerId) return
          el.dispatchDate = null
        })
        this.partners = partners
        this.getPartners()
      }).catch((err) => {
        const errorMessage = (err.response && err.response.data.message) || 'Something went wrong.'
        this.$toast.open({
          message: errorMessage,
          type: 'error',
          position: 'bottom',
          duration: 3000
        })
      })
    },
    getTimeLeft (date) {
      if (!date) return 0
      const cooldownDate = moment(date).add(4, 'weeks')
      const today = moment()
      const diff = cooldownDate.diff(today, 'days')
      return diff < 0 ? 0 : diff
    },
    cancelNext () {
      this.$http.post('/partners/next', {
        isNext: false
      }, { headers: { Authorization: `Bearer ${localStorage.fil_jwt}` } })
        .then((response) => {
          this.nextPartner = null
          this.nextAbility = null
          this.dispatchingNextAbility = null
          this.getPartners()
        })
        .catch((err) => {
          const errorMessage = (err.response && err.response.data.message) || 'Something went wrong.'
          this.$toast.open({
            message: errorMessage,
            type: 'error',
            position: 'bottom',
            duration: 3000
          })
        })
    },
    closeModal () {
      this.dispatching = null
      this.isModalOpen = false
      this.isDispatching = false
      this.isNextDispatch = false
      this.dispatchingNextAbility = null
    },
    tryDispatch (partnerId) {
      this.dispatchDate = new Date()
      this.dispatching = partnerId
      this.dispatchingAbility = this.partnerIds[partnerId].ability
      this.isModalOpen = true
      this.isNextDispatch = false
      this.isDispatching = true
      this.isAbilityChange = false
    },
    editAbility (partner) {
      this.dispatching = partner
      this.dispatchingNextAbility = this.partnerIds[partner].ability
      this.isModalOpen = true
      this.isNextDispatch = false
      this.isDispatching = false
      this.isAbilityChange = true
    },
    tryDispatchNext (partnerSecrid) {
      // this.partners.forEach((el, i) => {
      //   if (el.secrid === partnerSecrid) this.dispatching = i
      // })
      this.dispatching = partnerSecrid
      this.dispatchingNextAbility = this.partnerIds[partnerSecrid].ability
      this.isModalOpen = true
      this.isNextDispatch = true
      this.isAbilityChange = false
      this.isDispatching = false
    },
    dispatchNext (partnerId) {
      this.$http.post('/partners/next', {
        partnerId: partnerId,
        ability: this.dispatchingNextAbility,
        isNext: true
      }, { headers: { Authorization: `Bearer ${localStorage.fil_jwt}` } }).then((response) => {
        this.nextAbility = this.dispatchingNextAbility
        this.nextPartner = partnerId
        this.partnerIds[partnerId].ability = this.nextAbility
        this.dispatching = null
        this.isModalOpen = false
        this.isDispatching = false
        this.isAbilityChange = false
        this.isNextDispatch = false
        this.dispatchingNextAbility = null
        this.getPartners()
      }).catch((err) => {
        const errorMessage = (err.response && err.response.data.message) || 'Something went wrong.'
        this.$toast.open({
          message: errorMessage,
          type: 'error',
          position: 'bottom',
          duration: 3000
        })
      })
    },
    saveAbility (partnerId) {
      this.$http.post('/partners/ability', {
        partnerId: partnerId,
        ability: this.dispatchingNextAbility
      }, { headers: { Authorization: `Bearer ${localStorage.fil_jwt}` } }).then((response) => {
        // this.nextAbility = this.dispatchingNextAbility
        // this.nextPartner = partnerId
        // this.partnerIds[partnerId].ability = this.nextAbility
        this.dispatching = null
        this.isModalOpen = false
        this.isDispatching = false
        this.isNextDispatch = false
        this.isAbilityChange = false
        this.dispatchingNextAbility = null
        this.getPartners()
      }).catch((err) => {
        const errorMessage = (err.response && err.response.data.message) || 'Something went wrong.'
        this.$toast.open({
          message: errorMessage,
          type: 'error',
          position: 'bottom',
          duration: 3000
        })
      })
    },
    dispatch (partnerId) {
      const partnerIds = { ...this.partnerIds }
      this.$http.post('/partners/dispatch', {
        partnerId: partnerId,
        ability: this.dispatchingAbility,
        dispatchTime: this.dispatchDate
      }, { headers: { Authorization: `Bearer ${localStorage.fil_jwt}` } }).then((response) => {
        if (this.nextPartner === partnerId) {
          this.nextPartner = null
          this.nextAbility = 0
        }
        this.dispatching = null
        this.isModalOpen = false
        this.isDispatching = false
        this.isAbilityChange = false
        this.isNextDispatch = false
        partnerIds[partnerId].dispatchDate = this.dispatchDate
        partnerIds[partnerId].ability = this.dispatchingAbility
        this.dispatchingAbility = null
        this.partnerIds = partnerIds
        this.getPartners()
      }).catch((err) => {
        const errorMessage = (err.response && err.response.data.message) || 'Something went wrong.'
        this.$toast.open({
          message: errorMessage,
          type: 'error',
          position: 'bottom',
          duration: 3000
        })
      })
    }
  }
}
</script>

<style scoped lang="stylus">
  .partners
    padding 20px
    max-width 700px
    margin 0 auto

    .partners-list
      padding 10px
      padding-top 0
      margin-bottom 20px

    .title
      color #d29bb3
      font-size 2rem
      font-family: 'Sacramento', cursive;
      padding 5px

    .partner-container
      position relative
      .counter
        background-color #e6e8f5
        position absolute
        top 0
        left 0
        bottom 0
        border-radius 5px 0 0 5px
        z-index 0
        overflow: hidden
        &:before
          content:'';
          top:0;
          bottom: 0
          left: 0
          width: 100vw;
          position: absolute;
          z-index:1;
          background-image: linear-gradient(to right, #e3f2fd, #e1bee7)
        &:after
          content:'';
          top:0;
          transform:translateX(100%) rotate(45deg);
          width:50%;
          top -200px
          bottom -200px
          position: absolute;
          z-index:2;
          animation: slide 5s infinite;
          background-image: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,.5) 40%, rgba(255,255,255,0) 99%, rgba(255,255,255,0) 100%)

    @keyframes slide
      0% {transform:translateX(-400%) rotate(45deg);}
      50% {transform:translateX(400%) rotate(45deg);}
      100% {transform:translateX(400%) rotate(45deg);}

    .partner-row
      position relative
      padding 5px
      display flex
      flex-direction row
      border 2px solid #b6c6ff
      margin-bottom 10px
      box-shadow  0px 0px 0 1px #ccc
      .partner-image
        position relative
        background linear-gradient(to bottom, #ffecb3, rgba(255,236,179,0%))
        img
          height 100px
          border 2px solid #ffecb3
          box-shadow  0px 0px 0 1px #ccc, 3px 3px #ddd
          z-index 1

      .partner-data
        display flex
        flex-direction column
        flex: 1
        align-items flex-start
        height 100px
        .name
          width: 100%
          background linear-gradient(to right, #a1b4f5, #a1b4f5 40%, rgba(161,180,245,.18))
          text-align left
          padding 2px 15px
          color white
          font-weight 500
          font-size 0.9rem
          position relative
        .details
          padding 5px 5px 5px 15px
          font-size 0.8rem
          flex 1
          width: 100%
          text-align left
          display flex
          flex-direction column
          .info
            flex: 1
            color: #666
            font-weight 400
            font-size 0.70rem
            line-height 180%
          .action
            display flex
            flex-direction row
            justify-content flex-end
            button
              color white
              font-size 0.76rem
              border 1px solid white
              box-shadow 0px 0px 0 1px #f06292
              background linear-gradient(45deg, #f06292, #f062be)
              padding 3px 5px
              font-weight 500
              &:active
                background #ba68c8 !important
                box-shadow 0px 0px 0 1px #ba68c8 !important
              &.dispatch-next
                box-shadow 0px 0px 0 1px #2196f3
                background linear-gradient(45deg, #2196f3, #9262f0)
                margin-right 10px
              &.cancel
                box-shadow 0px 0px 0 1px crimson
                background linear-gradient(45deg, #f06292, crimson)
  .dispatch-modal, .ability-modal
    .title-row
      display flex
      flex-direction row
      align-items flex-start
      .img
        height 50px
        width 50px
        background-size cover
        background-repeat no-repeat
        background-position top center
        border 2px solid #ffecb3
        box-shadow  0px 0px 0 1px #ccc, 3px 3px #ddd
      .name
        flex 1
        background linear-gradient(to right, #a1b4f5, #a1b4f5 40%, rgba(161,180,245,.18))
        text-align left
        padding 2px 15px
        color white
        font-weight 500
        font-size 0.9rem
        position relative
    .action
      display flex
      flex-direction row
      justify-content center
      margin-top 10px
      button
        color white
        font-size 0.8rem
        border 1px solid white
        box-shadow 0px 0px 0 1px #f06292
        background linear-gradient(45deg, #f06292, #f062be)
        padding 3px 5px
        font-weight 500
        &:active
          background #ba68c8
          box-shadow 0px 0px 0 1px #ba68c8
        &:disabled
          background #888 !important
          box-shadow 0px 0px 0 1px #888 !important

    .form-row
      padding 10px 5px
      display flex
      flex-direction column
      min-width 150px
      flex 1
      label
        font-weight 500
        font-size 0.9rem
        padding 2px 0
        display block
        margin-right 5px
        text-align left
      input[type=number]
        width: 100%
        padding 6px
        border 1px solid #955a74
        border-radius 3px

    .form
      display: flex
      flex-direction row
      flex-wrap wrap

  .next
    position absolute
    right 2px
    top 2px
    display flex
    align-items center
    color white
    span
      font-size 0.65rem
      font-weight 500
      background #9c27b0
      padding 0 5px
      display block
      text-transform uppercase

  .action
    button
      margin-right 10px
      &:last-child
        margin-right 0

  .time-left
    position relative
    width 100%
    border-radius 5px
    // border 1px solid #e4b2c8
    .time
      z-index 2
      position relative
      top 0
      left 0
      bottom 0
      right 0
      font-size 0.7rem
      font-weight 500
  .overall-ability
    font-size 0.7rem
    font-weight 500

  a.edit
      font-size 0.65rem
      font-weight 500
      background #9c27b0
      padding 0 5px
      display inline-block
      text-transform uppercase
      margin-left: 4px
      color: white
      line-height 1rem
</style>
