<template lang='pug'>
  .navbar
    .navbar-container(:class="{hidden: currentRouteName === 'Home'}")
      .tabs
        router-link.tab(to="/" :class="{selected: currentRouteName === 'Partners'}")
          img.img(src="@/assets/images/tabicons/group.svg")
          .text My Partners
        router-link.tab(to="/group" :class="{selected: currentRouteName === 'Group'}")
          img.img(src="@/assets/images/tabicons/building.svg")
          .text My Group
        router-link.tab(to="/settings" :class="{selected: currentRouteName === 'Settings'}")
          img.img(src="@/assets/images/tabicons/settings.svg")
          .text My Settings

</template>

<script>
export default {
  name: 'NavBar',
  props: ['onTabSelected'],
  data () {
    return {
    }
  },
  mounted () {

  },
  methods: {

  },
  computed: {
    currentRouteName () {
      return this.$route.name
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
  .navbar
    position fixed
    top auto
    left 0
    right 0
    bottom 0
    // background rgba(255, 255, 255, 0.7)
    z-index 1000

    .hidden
      display none

    .tabs
      display flex
      flex-direction row
      align-items center
      justify-content space-between
      font-size 0.65em
      font-weight 500
      // max-width 700px
      box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
      margin 0 auto

      .tab
        padding 5px 5px
        border-left: 1px solid #9d8db5
        border-top: 1px solid #9d8db5
        height 70px
        flex 1
        background #7e6c9e
        display flex
        flex-direction column
        justify-content center
        align-items center
        text-decoration none
        color #fff

        &.selected
          color #523f6e
          background #fff !important
          border-color: linen

        &:last-child
          border-right: 1px solid #9d8db5

        &:hover
          background #9d8db5
          cursor pointer

        .img
          height 24px
          margin-bottom 3px

  @media only screen and (max-width: 600px)
    .navbar
      top auto
      left 0
      right 0
      bottom 0
</style>
